import { HttpHeaders } from '@angular/common/http';
import data from '../config/app-config';

export const GlobalVariable = Object.freeze({
    SERVER_URL: data.server_host,
	API_SERVER_URL: data.server_host+"snapquick/",
	APP_DATA:data.server_host+data.app_data,
    httpOptions : {
        headers: new HttpHeaders({ 
          'Content-Type': 'application/json',
          'Authorization':"143&b5933b1e3c4023774366da04a515ef37"})
	  },
	GAPI_SCRIPT:'https://apis.google.com/js/api.js',
    Dictionary:{
        MOV_ING: "MOVING",
		MOV_EGR: "MOVEGR",
		ING_INV_INICIAL: "III",
		ING_AJUSTE: "IPA",
		ING_COMPRA: "ID",
		ING_PRODUCCION: "IPP",
		ING_SOBRANTE: "IPS",
		ING_TRASPASO: "IPT",
		EGRE_FACTURACION: "FACT",
		EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA: "FACTCOMPEL",
		EGRE_FACTURACION_MANUAL: "FACTMAN",
		EGRE_BAJA: "BAJA",
		EGRE_PROFORMA: "PFR",
		EGRE_TRASPASO: "TRAS",
		EGRE_AJUSTE: "AJU",
		EGRE_PRODUCCION:"SPP",
		EGRE_CONSUMO:"SPCON",
		EGRE_PUNTO_VENTA:"SPPV",
		EGRE_VENTA_PERSONAL:"SPVAP",
		EGRE_MERMA:"SPM",
		EGRE_FALTA_INVENTARIO:"SPFI",
		EGRE_COTIZACION: "COT",
		FACT_IMPRESION_VACIA: "VAC",
		FACT_IMPRESION_COMPLETA: "COMPL",
		FACT_IMPRESION_SEMICOMPLETA: "SEMICOMPL",
		PAPEL_OFICIO: "OFICIO",
		PAPEL_CARTA: "CARTA",
		PAPEL_CARTA_MEDIDA:[612, 792],
		PAPEL_MEDIA_CARTA_MEDIDA:[612, 396],
		PAPEL_OFICIO_MEDIDA:[612, 936],
		PAPEL_MEDIO_OFICIO_MEDIDA:[612, 468],
		PAPEL_OFICIO_HORIZONTAL_MEDIDA:[936,612],
		PAPEL_MEDIOOFICIO: "MEDOFI",
		PAPEL_MEDIACARTA: "MEDCAR",
		PAPEL_ROLLO: "ROLLO",
		TIPOS_PAGOS: "TIPA",
		PAPEL_CUARTOCARTA: "CUACAR",
		TIPO_PAGO_CREDITO: "CREDITO",
		TIPO_PAGO_CRE: "CRE",
		TIPO_PAGO_GFTC: "GFTC",
		TIPO_PAGO_TARJ: "TARJCRE",
		TIPO_PAGO_TRANS: "TRANSBANC",
		TIPO_PAGO_CODQR: "CODQR",
		TIPO_PAGO_ENLINEA: "TIPPAGENL",
		TIPO_PAGO_CHEQUE: "TIPPAGCHE",
		TIPO_PAGO_CONTADO: "CONTADO",
		TIPO_PAGO_CONT: "CONT",
		TIPO_PAGO_CONTADO_TRANSFERENCIA: "CONTTRANS",
		TIPO_PAGO_OTRO: "TIPPAGOTRO",
		CENTRO_COSTO_ALMACEN: "ALMACEN",
		ROL_ADMINISTRADOR: "ADMINISTRADOR",
		ROL_CONTABILIDAD: "CONTABILIDAD",
		ROL_VENDEDOR: "VENDEDOR",
		ROL_ESTUDIANTE: "ESTUDIANTE",
		ROL_PROFESOR: "PROFESOR",
		ROL_TUTOR:"TUTOR",
		DIA_LUNES: "LUN-1",
		DIA_MARTES: "MAR-2",
		DIA_MIERCOLES: "MIE-3",
		DIA_JUEVES: "JUE-4",
		DIA_VIERNES: "VIE-5",
		DIA_SABADO: "SAB-6",
		DIA_DOMINGO: "DOM-0",
		USUARIO_SUPERADMIN: "SUPER-ADMINISTRADOR",
		MENU_RUTAS: "RUTAS",
		MENU_SEGUIMIENTOAPP: "SEGUIMIENTO APP",
		MENU_APPMOVIL: "APP MOVIL",
		MENU_PANTALLACLIENTE: "PANTALLA CLIENTE",
		MENU_PANTALLADESPACHO: "PANTALLA DESPACHO",
		TIPO_PRODUCTO_BASE: "PBASE",
		TIPO_PRODUCTO_INTER: "PINTER",
		TIPO_PRODUCTO_FINAL: "PFINAL",
		DESTINO_CIERRE_BANCO: "DBANCO",
		DESTINO_CIERRE_CAJA: "CAJA",
		DESTINO_CIERRE_SIGUIENTETURNO: "SIGUIENTETURNO",
		ESTADO_MESA_OCUPADO: 'OCU',
		ESTADO_MESA_DISPONIBLE: 'DIS',
		ESTADO_MESA_RESERVADO: 'RE',
		MENU_PEDIDOS_MESAS: "PEDIDOS MESAS",
		MENU_PLAN_CUENTAS: "PLAN CUENTAS",
		MENU_COMPROBANTES: "COMPROBANTES",
		MENU_MEDICO: "PACIENTES",
		IVA_DF: "IVA DF",
		IVA_CF: "IVA CF",
		IT: "IT",
		IT_POR_PAGAR: "IT POR PAGAR",
		MONEDAS_CAJA : "CJMNDS",
		BANCOS_CAJA : "CJBNS",
		TIPOS_CAJA : "CJTPS",
		TIPO_CAJA_CUENTA_BANCARIA : "CJCB",
		TIPOS_MOVIMIENTOS_CAJA : "CJCONMOV",
		CONCEPTO_MOVIMIENTO_CAJA_DEPOSITO_VENTAS:"DEVVENCJ",
		CONCEPTO_MOVIMIENTO_CAJA_OTROS_INGRESOS:"OTROINGCJ",
		MODULO_VENTAS:"VENTAS",
		MODULO_COMPRAS:"COMPRAS",
		MODULO_USUARIOS:"USUARIOS",
		MODULO_FACTURACION:"FACTURACIÓN",
		MODULO_INVENTARIO:"INVENTARIO",
		MODULO_VENCIMIENTOS_PRODUCTOS:"VENCIMIENTOS PRODUCTOS",
		MODULO_VENCIMIENTOS_ECONOMICOS_VENTAS:"VENCIMIENTOS ECONOMICOS VENTAS",
		MODULO_VENCIMIENTOS_ECONOMICOS_COMPRAS:"VENCIMIENTOS ECONOMICOS COMPRAS",
		MODULO_PEDIDOS_RESTAURANTE:"PEDIDOS RESTAURANTE",
		MODULO_PEDIDOS_DISTRIBUCION:"PEDIDOS DISTRIBUCIÓN",
		MODULO_PANTALLA_CLIENTE:"PANTALLA CLIENTE",
		MODULO_PANTALLA_DESPACHO:"PANTALLA DESPACHO",
		MODULO_MESAS:"MESAS",
		MODULO_SALAS:"SALAS",
		MODULO_RECETAS:"RECETAS",
		MODULO_PRECIOS_PRODUCTOS:"PRECIOS PRODUCTOS",
		MODULO_INVENTARIO_EXTERNO:"INVENTARIO EXTERNO",
		PRECIO_GENERAL_PRODUCTO:"PREGN",
		MODULO_MEDICINA_DIETAS:"MEDICINA DIETAS",
		MODULO_MEDICINA_CONSULTORIO:"CONSULTORIO",
		MODULO_MEDICINA_LABORATORIO:"LABORATORIO",
		MODULO_REPOSICIONES:"REPOSICIONES",
		MODULO_COMBOS:"COMBOS",
		MODULO_DEVOLUCIONES_VENTAS:"DEVOLUCIONES VENTAS",
		MODULO_PRECIOS_PRODUCTOS_SUCURSAL:"PRECIOS PRODUCTOS SUCURSAL",
		MODULO_PRECIOS_PRODUCTOS_GRUPOS:"PRECIOS PRODUCTOS GRUPOS",
		MODULO_PAGOS_SERVICIOS_EMPRESAS:"PAGOS SERVICIOS EMPRESAS",
		MODULO_ALMACENES_EMPLEADO:'ALMACENES EMPLEADO',
		MODULO_LIBRO_VENTAS_V2:'LIBRO DE VENTAS V2',
		MODULO_COBROS_BANCO_COLEGIO:'COBROS BANCO COLEGIO',
		MODULO_COBROS_CAJA_COLEGIO:'COBROS CAJA COLEGIO',
		MODULO_GEOREFERENCIACION_USUARIOS:"GEOREFERENCIACION USUARIOS",
		MODULO_COTIZACIONES:"COTIZACIONES",
		MODULO_PEDIDOS_PRODUCCION:"PEDIDOS PRODUCCION",
		MODULO_SERVICIOS:"SERVICIOS",
		MODULO_PRECIOS_PRODUCTOS_CLIENTES:"PRECIOS PRODUCTOS CLIENTES",
		MODULO_PRECIOS_PRODUCTOS_USUARIOS:"PRECIOS PRODUCTOS USUARIOS",
		MODULO_SUCURSALES:"SUCURSALES",
		MODULO_FACTURACION_A_TERCEROS:"FACTURACIÓN A TERCEROS",
		MODULO_DESPACHOS:"DESPACHOS",
		MODULO_BIOMETRICO_ASISTENCIA:"BIOMETRICO ASISTENCIA",
		MODULO_CLIENTES_SUCURSAL:"CLIENTES SUCURSAL",
		MODULO_CLIENTES_CONTACTOS:"CLIENTES CONTACTOS",
		MODULO_OBSEQUIOS:"OBSEQUIOS",
		MODULO_RESTRICCION_PRODUCTOS_VENTA:"RESTRICCION PRODUCTOS VENTA",
		MODULO_CONTABILIDAD:"CONTABILIDAD",
		MODULO_CONTABILIDAD_SEMI_AUTOMATICA:"CONTABILIDAD SEMI-AUTOMATICA",
		MODULO_INVENTARIO_MINIMO:"INVENTARIO MÍNIMO",
		MODULO_INVENTARIO_FLEXIBLE:"INVENTARIO FLEXIBLE",
		MODULO_KARDEX_INVENTARIO_PRODUCTOS:"KARDEX DE INVENTARIO DE PRODUCTOS",
		MODULO_PEDIDOS_EN_LINEA:"PEDIDOS EN LINEA",
		MODULO_CONTROL_POR_USUARIOS:"CONTROL POR USUARIOS",
		MODULO_CONSUMO_DE_AGUA:"CONSUMO DE AGUA",
		MODULO_COMBOS_CONCEPTOS:"COMBOS CONCEPTOS",
		MODULO_CONTEO_DE_DINERO:"CONTEO DE DINERO",
		MODULO_CAJA_POS:"CAJA POS",
		MODULO_CAJA_GENERAL:"CAJA GENERAL",
		MODULO_EMPLEADOS:"EMPLEADOS",
		MODULO_CUENTAS_EMPLEADOS:"CUENTAS EMPLEADOS",
		MODULO_PROFESORES_INSTITUTO:"PROFESORES INSTITUTO",
		MODULO_PROFESORES_COLEGIO:"PROFESORES COLEGIO",
		MODULO_ACCESO_NIVEL_ESCOLAR:"ACCESO POR NIVEL ESCOLAR",
		MODULO_ACCESO_GESTION_ESCOLAR:"ACCESO POR GESTION ESCOLAR",
		MODULO_APLICACIONES_POR_ROL:"APLICACIONES POR ROL",
		MODULO_REGISTRO_PEDAGOGICO:"REGISTRO PEDAGÓGICO",
		MODULO_CALENDARIO_CURSOS:"CALENDARIO CURSOS",
		MODULO_IMAGENES_IMPRESION_COTIZACION:'IMÁGENES IMPRESIÓN',
		MODULO_VEHICULOS:'VEHICULOS',
		MODULO_BUSES:'BUSES',
		MODULO_MANTENIMIENTO_VEHICULOS:'MANTENIMIENTO VEHICULOS',
		MODULO_MENSAJERIA:'MENSAJERIA',
		MODULO_ALMACENES:'ALMACENES',
		MODULO_ALMACENES_USUARIO:'ALMACENES USUARIO',
		MODULO_GECE:'GECE',
		MODULO_GEOREFERENCIACION_CLIENTES:'GEOREFERENCIACION CLIENTES',
		MODULO_DELIVERY:'DELIVERY',
		MODULO_PERMISOS_AVANZADOS_USUARIOS:'PERMISOS AVANZADOS USUARIOS',
		MODULO_ESTUDIANTES_COLEGIO:'ESTUDIANTES COLEGIO',
		MODULO_ESTUDIANTES_INSTITUTO:'ESTUDIANTES INSTITUTO',
		MODULO_CUENTAS_ESTUDIANTES:'CUENTAS DE ESTUDIANTES',
		MODULO_HOJA_RUDE:'HOJA DE RUDE',
		MODULO_INGRESOS_ECONOMICOS_COLEGIO:'INGRESOS ECONÓMICOS COLEGIO',
		MODULO_INGRESOS_ECONOMICOS_INSTITUTO:'INGRESOS ECONÓMICOS INSTITUTO',
		MODULO_MENSAJERIA_REDES_SOCIALES:"MENSAJERIA REDES SOCIALES",
		MODULO_AUTOMATIZACION_MENSAJES_REDES:"AUTOMATIZACIÓN DE MENSAJES",
		MODULO_GENERADOR_CODIGOS_BARRAS:"GENERADOR CODIGOS BARRAS",
		MODULO_FACTURACION_COMPUTARIZADA_EN_LINEA:"FACTURACIÓN COMPUTARIZADA EN LÍNEA",
		MODULO_FACTURACION_MANUAL:"FACTURACIÓN MANUAL",
		MODULO_BITACORA:"BITACORA",
		MODULO_PERIODISMO:"PERIODISMO",
		MODULO_IMPRESION_NATIVA:"IMPRESIÓN NATIVA",
		MODULO_IMPORTACION_PAGOS:"IMPORTACION DE PAGOS",
		MODULO_BLOQUEO_CLIENTES:"BLOQUEO CLIENTES",
		MODULO_FACTURACION_COMPUTARIZADA_EN_LINEA_POR_SUCURSAL:"FACTURACIÓN COMPUTARIZADA EN LÍNEA POR SUCURSAL",
		MODULO_FACTURACION_COMPUTARIZADA_EN_LINEA_POR_EMPRESA:"FACTURACIÓN COMPUTARIZADA EN LÍNEA POR EMPRESA",
		MODULO_FACTURACION_COMPUTARIZADA_EN_LINEA_POR_SUCURSAL_EMPRESA:"FACTURACIÓN COMPUTARIZADA EN LÍNEA POR SUCURSAL EMPRESA",
		MODULO_PRODUCTOS_SUCURSAL:"PRODUCTOS SUCURSAL",
		MODULO_HOTELES:"HOTELES",
		MODULO_DESCUENTO_GLOBAL:"DESCUENTO GLOBAL",
		MODULO_DESCUENTO_GLOBAL_COMPRAS:"DESCUENTO GLOBAL COMPRAS",
		MODULO_VENTAS_APP_OFFLINE:"VENTAS APP OFFLINE",
		MODULO_FECHAS_VENCIMIENTO_PAGOS_ESTUDIANTES:"FECHAS VENCIMIENTO PAGOS ESTUDIANTES",
		MODULO_PRODUCCION:"PRODUCCIÓN",
		MODULO_CAJA_POS_SUCURSAL:"CAJA POS SUCURSAL",
		MODULO_GOOGLE_CLASS_ROOM:"GOOGLE CLASS ROOM",
		MODULO_TORNEOS:"TORNEOS",
		ESTPED_PEND:"PENDIENTE",
		ESTPED_PCOMP:"PORCOMPLETAR",
		ESTPED_PROD:"PRODUCIDO",
		ESTPED_ENTGD:"ENTREGADO",
		ESTPED_ENTGDINC:"ENTREGADOINC",
		TIPO_COMPROBANTE_INGRESO: "TCMCING",
		TIPOS_COMPROBANTES: "TCMC",
		TEXTOS_SISTEMA_EMPRESA:"TEXTOS_SISTEMA_EMPRESA",
		TEXTO_CLIENTE_CATEGORIA:"CLIENTE_CATEGORIA",
		TEXTO_CLIENTE_CATEGORIA_2:"CLIENTE_CATEGORIA_2",
		TEXTO_CLIENTE_SUBCATEGORIA:"CLIENTE_SUBCATEGORIA",
		TEXTO_CLIENTE_RAZON_SOCIAL:"CLIENTE_RAZON_SOCIAL",
		TEXTO_RECIBO_NOTA_VENTA:"RECIBO_NOTA DE VENTA",
		TEXTO_CLIENTE_NIT:"CLIENTE_NIT",
		TEXTO_DETVENTA_CAMPO1:"CAMPO_1",
		TEXTO_PRODUCTO_NOMBRE:"PRODUCTO_NOMBRE",
		TEXTO_PRODUCTO_DESCRIPCION:"PRODUCTO_DESCRIPCION",
		TEXTO_PRODUCTO_GRUPO:"PRODUCTO_GRUPO",
		TEXTO_PRODUCTO_SUBGRUPO:"PRODUCTO_SUBGRUPO",
		TEXTO_PEDIDO_CATEGORIA:"PEDIDO_DESPACHO",
		TIPO_FUENTE_DESPACHO_COCINA:"RESTTICOC",
		TIPO_FUENTE_DESPACHO_BAR:"RESTTIBAR",
		TIPO_DOCUMENTO_FACTURA:"TIPDOCFACT",
		TIPO_DOCUMENTO_RECIBO:"TIPDOCREC",
		TIPO_CONCEPTO_EGRESOS:"CONCEGRE",
		TIPO_CATEGORIA_AFILIADO:"TIPCATAFI",
		TIPO_MENSAJE_TRASPASO:"TIPMENSTRAS",
		TIPO_FUENTE_COMPROBANTE_MANUAL:"TFCM",
		TIPO_FUENTE_COMPROBANTE_SEMIAUTOMATICA_VENTA:"TFCSV",
		TIPO_FUENTE_COMPROBANTE_AUTOMATICA_PAGO_VENTA:"TFCAPV",
		TIPO_PRODUCTO_PRECIOS:"PRECIOS PRODUCTOS",
		TIPO_PRODUCTO_GRUPOS:"GRUPOS PRODUCTOS",
		TIPO_PRUEBA_CLASIFICACIONES:"CLASIFICACIONES PRUEBAS",
		TIPO_EQUIPO_CATEGORIAS:"EQUIPOS CATEGORIAS",
		TIPO_CAMPEONATO_FASES:"CAMPEONATO FASES",
		TIPO_ROLES_PARTIDOS:"ROLES PARTIDOS",
		TIPO_CONSULTAS_MEDICAS:"CONSULTAS MEDICAS",
		TIPO_TRATAMIENTOS_MEDICOS:"TRATAMIENTOS MEDICOS",
		TIPO_EQUIPO_NIVELES:"EQUIPOS NIVELES",
		TIPO_EQUIPO_DISCIPLINAS:"EQUIPOS DISCIPLINAS",
		TIPO_ESTUDIANTE_CATEGORIAS:"CATEGORIAS ESTUDIANTES",
		TIPO_INSCRIPCION_MODALIDADES:"TIPMODEDU",
		TIPO_INSCRIPCION_PUBLICIDAD:"TIPMODPUB",
		TIPO_CONCEPTO_PAGOS:"CONCEPTO PAGOS",
		TIPO_DESPACHOS:"REST_DESP",
		TIPO_ESTADO_ASUNTO_ADMINISTRATIVO:"SNAPESTASAD",
		TIPO_DESCRIPCIONES_DETALLE_PEDIDO:"DESC_DET_PED",
		TIPO_DISENIOS_LOGO_IMPRESION:"SNAPDISLOGIMP",
		DISENIO_LOGO_IMPRESION_IZQUIERDA:"LOGIZQ",
		DISENIO_LOGO_IMPRESION_ARRIBA:"LOGARR",
		IMPRESION_CODIGO_PRODUCTO_SISTEMA:"IMPRESION_CODIGO_PRODUCTO_SISTEMA",
		IMPRESION_CODIGO_PRODUCTO_SIN:"IMPRESION_CODIGO_PRODUCTO_SIN",
		TIPO_COMBO_GRUPOS:"GRUPOS COMBOS",
		TIPO_PRODUCTO_SUBGRUPOS:"SUBGRUPOS PRODUCTOS",
		ESTADO_VENTA_SANEADA:'ESTVENTSAN',
		CANAL_VENTA_DIRECTA:'CANVENDIR',
		CANAL_VENTA_ECOMERCE:'CANVENECOM',
		ITEM_PRODUCTO:'ITEM_PRODUCTO',
		ITEM_SERVICIO:'ITEM_SERVICIO',
		ITEM_COMBO:'ITEM_COMBO',
		DEPENDENCIAS_ESCUELA:'SCHDUE',
		TURNOS_ESCUELA:'SCHTUES',
		TIPOS_CALCULO_NOTAS_ESCUELA:'SCHTCN',
		GENEROS:'GNR',
		RRHH_CARGOS:'RRHH_CARGOS',
		CODIGO_APP_EMPRESAS:"APP002",
		CODIGO_APP_PRODUCTOS:"APP006",
		CODIGO_APP_LLANTAS:"APP051",
		CODIGO_APP_REPUESTOS:"APP052",
		CODIGO_APP_VEHICULOS:"APP050",
		CODIGO_APP_VENTAS:"APP013",
		CODIGO_APP_RESERVAS:"APP067",
		CODIGO_APP_REPORTE_VENTAS_PRODUCTOS:"APP018",
		CODIGO_APP_REPORTE_COMPRAS_PRODUCTOS:"APP020",
		CODIGO_APP_REPORTE_VENTAS:"APP040",
		CODIGO_APP_COMPRAS:"APP011",
		CODIGO_APP_RUTAS:"APP057",
		CODIGO_APP_VIAJES:"APP041",
		CODIGO_APP_TAREAS:"APP056",
		CODIGO_APP_ESTUDIANTES:"APP059",
		CODIGO_APP_CARRERAS:"APP060",
		CODIGO_APP_CONCEPTOS_INGRESOS_INSTITUTO:"APP061",
		CODIGO_APP_PANTALLA_DESPACHO:"APP026",
		CODIGO_APP_CUENTAS_POR_PAGAR:"APP023",
		CODIGO_APP_CUENTAS_POR_COBRAR:"APP022",
		CODIGO_APP_PEDIDOS:"APP028",
		CODIGO_APP_CLIENTES:"APP004",
		CODIGO_APP_CITAS_MEDICAS:"APP079",
		CODIGO_APP_TRATAMIENTOS:"APP081",
		CODIGO_APP_CIERRES_CAJA:"APP027",
		CODIGO_APP_PROVEEDORES:"APP005",
		CODIGO_APP_EMPLEADOS:"APP039",
		CODIGO_APP_INVENTARIOS:"APP012",
		CODIGO_APP_MOVIMIENTOS_INVENTARIO:"APP036",
		CODIGO_APP_COTIZACIONES:"APP029",
		CODIGO_APP_MOVIMIENTOS_CAJA:"APP070",
		CODIGO_APP_LECTURACION:"APP043",
		CODIGO_APP_VECINOS:"APP042",
		CODIGO_APP_COMBOS:"APP037",
		CODIGO_APP_USUARIOS:"APP003",
		CODIGO_APP_SEGUIMIENTO_ESTUDIANTES:"APP046",
		CODIGO_APP_ROL_PARTIDOS:"APP078",
		CODIGO_APP_PRUEBAS_CLINICAS:"APP080",
		TIPO_GESTIONES:"GTN",
		TIPO_SEMESTRES_GESTION:"TIPSEMGTN",
		TIPO_ESTADOS_ASISTENCIA:"ASTESC",
		TIPO_INTERVALOS_ESCOLAR:"INTESC",
		TIPO_EVENTOS:"SCHTIPEVE",
		CALCULO_CALIFICACION_NOTA_CNT:"NOTA-CNT",
		CALCULO_CALIFICACION_NOTA_CNP:"NOTA-CNP",
		TIPO_RECURSOS_MULTIMEDIA:"TIPRECMUL",
		TIPO_MESES:"MESES",
		TIPO_ESTADOS_RESERVAS:"EM",
		TIPO_RECURSO_CARPETA:"TIPRECCARP",
		TIPO_RECURSO_ARCHIVO:"TIPRECARCH",
		TIPO_RECURSO_URL:"TIPRECURL",
		TIPO_PERIODICIDAD:"TIPPERIOD",
		TIPO_PERIODO_INSTITUTO:"TIPPERINST",
		TIPO_CORRELATIVO_CODIGO_ESTUDIANTE:"CORR_COD_EST",
		TIPO_CORRELATIVO_CODIGO_PRODUCTO:"CORR_COD_PROD",
		TIPO_CORRELATIVO_CODIGO_CONCEPTO_ING:"CORR_COD_CONING",
		TIPO_CORRELATIVO_CODIGO_CLIENTE:"CORR_COD_CLI",
		TIPO_CORRELATIVO_CODIGO_CAMPEONATO:"CORR_COD_CAMP",
		TIPO_CORRELATIVO_CODIGO_ROL_PARTIDO:"CORR_COD_ROLP",
		TIPO_CORRELATIVO_CODIGO_EQUIPO:"CORR_COD_EQUI",
		TIPO_CORRELATIVO_CODIGO_JUGADOR:"CORR_COD_JUG",
		TIPO_CORRELATIVO_CODIGO_OT:"CORR_COD_OT",
		TIPO_CORRELATIVO_CODIGO_CHEQUE:"CORR_COD_CHEQUE",
		TIPO_CORRELATIVO_CODIGO_PROVEEDOR:"CORR_COD_PRO",
		TIPO_CORRELATIVO_CODIGO_VECINO:"CORR_COD_VEC",
		TIPO_CORRELATIVO_CODIGO_EMPLEADO:"CORR_COD_EMP",
		TIPO_CORRELATIVO_CODIGO_CURSO:"CORR_COD_CUR",
		TIPO_CORRELATIVO_CODIGO_MATERIA:"CORR_COD_MAT",
		SELECCION_TODOS:{id:0,nombre:"TODOS",habilitado:true},
		TIPO_CALCULO_FECHA_VENCIMIENTO_PAGO_INSTITUTO:"TIPFVPI",
		DESPACHO_MESA:"PED_MES",
		CONVERSIONES_MOVIMIENTO_INVENTARIO:"MICONMOVINV",
		CONVERSION_MOVIMIENTO_INVENTARIO_MIGRACION:"CONMIMIG",
		CONVERSION_MOVIMIENTO_INVENTARIO_PRODUCCION:"CONMIPRO",
		CONVERSION_MOVIMIENTO_INVENTARIO_TRASPASO:"CONMITRAS",
		CONVERSION_MOVIMIENTO_INVENTARIO_MEZCLA:"CONMIMEZ",
		TIPO_CATEGORIA_SEGUIMIENTO_ESTUDIANTE:"CAT_SEG_EST",
		LONGITUD_BASE_IMPRESION_PEDIDO:250,
		LETRAS_EXCEL:["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","AA","AB","AC","AD","AE","AF","AG","AH","AI","AJ","AK","AL","AM","AN","AO","AP","AQ","AR","AS","AT"],
		COPIA_IMPRESION_ADICION_HOJA:"COPIA_IMPRESION_ADICION_HOJA",
		COPIA_IMPRESION_MISMA_HOJA:"COPIA_IMPRESION_MISMA_HOJA",
		MKT:{
			MEN_ENVIO_RECIBO_FACTURA:"MKTENVRECFACT",
			MEN_ENVIO_RECORDATORIO_PAGO:"MKTENVRECPAG",
			MEN_ENVIO_AGRADECIMIENTO_PAGO:"MKTENVAGRPAG",
			MEN_ENVIO_FELICITACION_CUMPLE_CLIENTE_PERSONAL:"MKTENVFELCLIP",
			MEN_ENVIO_FELICITACION_CUMPLE_CLIENTE_GRUPAL:"MKTENVFELCLIG",
		},
		SIN:{
			DOCUMENTOS_IDENTIDAD:"SINDOCID",
			SIN_UNIDADES_MEDIDA:"SINUNIDMED",
			SIN_MOTIVOS_ANULACION:"SINMOTANU",
			SIN_EVENTOS_SIGNIFICATIVOS:"SINEVESIGN",
			TIPO_DOCUMENTO_CI:"1",
			URL_QR_PRODUCCION:"https://siat.impuestos.gob.bo/consulta/QR?",
			URL_QR_PRUEBAS:"https://pilotosiat.impuestos.gob.bo/consulta/QR?",
			CODIGO_PUNTO_VENTA:0
		},
		SCH:{
			TIPO_NIVEL_ESCOLAR:"SCHNE",
			TIPO_ESTUDIANTE_VIVE_CON:"EVC",
			NOMBRE_CORTO_VIVE_PADRE_Y_MADRE:"EVCPYM",
			NOMBRE_CORTO_VIVE_SOLO_PADRE:"EVCSP",
			NOMBRE_CORTO_VIVE_SOLO_MADRE:"EVCSM",
			NOMBRE_CORTO_VIVE_TUTOR:"EVCT",
			TIPO_IDIOMA:"SCHIDI",
			TIPO_PARENTESCO:"SCHPAR",
			TIPO_GRADO_INSTRUCCION:"SCHGI",
			TEXTO_PERIODOS_ANUAL:"TRIMESTRE",
			RUDE:{
				TIPO_DISCAPACIDAD:"SCHRUTIPDIS",
				TIPO_GRADO_DISCAPACIDAD:"SCHRUTIPGRADDIS",
				TIPO_PERTENENCIA_NACION:"SCHRUTIPPERNAC",
				TIPO_ASISTENCIA_CENTRO_SALUD:"SCHRUTIPASICENSAL",
				TIPO_CANTIDAD_ASISTENCIA_CENTRO_SALUD:"SCHRUTIPCANTASICENSAL",
				TIPO_CONDICION_VIVIENDA:"SCHRUTIPCONDVIV",
				TIPO_ACCESO_INTERNET:"SCHRUTIPACCINT",
				TIPO_FRECUENCIA_ACCESO_INTERNET:"SCHRUTIPFREPACCINT",
				TIPO_ACTIVIDAD_TRABAJO:"SCHRUTIPACTTRA",
				TIPO_TURNO_TRABAJO:"SCHRUTIPTURTRA",
				TIPO_FRECUENCIA_TRABAJO:"SCHRUTIPFRETRA",
				TIPO_LLEGADA_ESCUELA:"SCHRUTIPLLEESC",
				TIPO_TIEMPO_LLEGADA_ESCUELA:"SCHRUTIPTIELLEESC",
				TIPO_RAZON_ABANDONO_ESCUELA:"SCHRUTIPRAZABAESC"
			},
			TIPO_DESCRIPCION_PAGO:"DESCRIPCION PAGO",
			CLASE_NIVEL_INICIAL:"INICIAL"
		},
		NBH:{
			PRODUCTO_CONSUMO_AGUA:"CONSUMO DE AGUA",
			PRODUCTO_GRUPO_MENSUAL:"MENSUAL",
			PRODUCTO_GRUPO_POR_VENTA:"POR VENTA",
			PRODUCTO_GRUPO_OCASIONAL:"OCASIONAL"
		},
		CONT:{
			NOMBRE_CUENTA_VENTAS:"VENTAS",
			NOMBRE_CUENTA_CUENTAS_X_COBRAR:"CUENTAS X COBRAR",
			NOMBRE_CUENTA_CAJA_BANCOS:"CAJA/BANCOS",
			NOMBRE_CUENTA_IVA_CF:"IVA CF",
			NOMBRE_CUENTA_IVA_DF:"IVA DF",
			NOMBRE_CUENTA_IT:"IT",
			NOMBRE_CUENTA_IT_POR_PAGAR:"IT POR PAGAR",
		}
	},
	MENSAJES_VALIDACION:{
		DATO_REQUERIDO:"Dato requerido!"
	}
});