<div class="modal-header card-header">
    <div class="col-4">
        <div class="row">
            <div class="col-6">
                <span class="text-blue text-125">Datos Compra</span>
            </div>
            <div class="col-6">
                <button  id="compra_guardar" type="button" class="btn btn-primary" (click)="guardarCompra()">
                    <i class="fa fa-save align-top bigger-125"></i>
                </button>&nbsp;
                <button type="button" (click)="guardarCompraBorrador()" [disabled]="compra.detallesCompra.length==0" class="btn btn-success">
                    <i class="fa fa-bookmark align-top bigger-125"></i>   
                </button>&nbsp;
                <button id="cerrarV" type="button" class="btn btn-primary btn-danger" (click)="cerrarComponente()" >
                    <i class="fa fa-times red2 align-top bigger-125"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="col-8" *ngIf="!inhabilitar_busqueda_item">
        <div class="row">
            <div class="col-12">
                <span class="text-blue text-125">Búsqueda Item</span>
            </div>
        </div>
    </div>
</div>
<div class="modal-body card-body">
    <div class="widget-body">
        <div class="row">
            <div class="col-md-4 col-xs-12 no-padding">
                <div class="widget-box">
                    <div class="widget-body">
                        <div class="widget-main">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-xs-12 no-padding">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>Tipo Doc.</label>
                                        <span class="red">(*)</span>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <select [compareWith]="compararObjectos" id="tipo_documento" name="tipo_documento" class="form-control" [(ngModel)]="compra.tipo_documento">
                                            <option [ngValue]="tipoDocumento" *ngFor="let tipoDocumento of tiposDocumentos">{{tipoDocumento.nombre}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 col-xs-12 no-padding">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>Num. Doc.</label>
                                        <span class="red">(*)</span>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <input class="form-control" name="compra_factura" type="number" placeholder="Número de documento" [(ngModel)]="compra.factura"/>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="compra.usuario.empresa.usar_sucursales || (compra.usuario.empresa.usar_inventario && (compra.usuario.empresa.usar_almacenes || compra.usuario.empresa.usar_almacenes_empleado))">
                                <hr>
                                <div class="row">
                                    <div *ngIf="usuario.empresa.usar_sucursales" class="col-md-6 col-sm-12 col-xs-12 no-padding">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label>Sucursal</label>
                                            <span class="red">(*)</span>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <select [compareWith]="compararObjectos" id="compra_sucursal" name="compra_sucursal" [disabled]="inhabilitar_sucursal" class="form-control" [(ngModel)]="compra.sucursal" (change)="obtenerAlmacenes()">
                                                <option [ngValue]="sucursal" *ngFor="let sucursal of sucursales">{{sucursal.nombre}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div *ngIf="usuario.empresa.usar_inventario && usuario.empresa.usar_almacenes" class="col-md-6 col-sm-12 col-xs-12 no-padding">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label>Almacen</label>
                                            <span class="red">(*)</span>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <select [compareWith]="compararObjectos" id="compra_almacen" name="compra_almacen" [disabled]="inhabilitar_almacen" class="form-control" [(ngModel)]="compra.almacen">
                                                <option [ngValue]="almacen" *ngFor="let almacen of almacenes">{{almacen.nombre}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="compra.tipo_documento.nombre_corto==global_variable.Dictionary.TIPO_DOCUMENTO_FACTURA">
                                <hr >
                                <div class="row">
                                    <div class="col-md-6 col-sm-12 col-xs-12 no-padding">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label>Cod. Autorización</label>
                                            <span class="red">(*)</span>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <input class="form-control" name="compra_autorizacion" type="text" placeholder="código autorizacion" [(ngModel)]="compra.autorizacion"/>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12 col-xs-12 no-padding">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label>Código de Control</label>
                                            <span class="red">(*)</span>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <input (blur)="verificarDuplicidadDocumento({which:13},compra.codigo_control)" (keypress)="formatearCodigoControl(compra.codigo_control)" class="form-control text-uppercase" name="compra_codigo_control" type="text" placeholder="codigo de control" [(ngModel)]="compra.codigo_control" />
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <hr>
                            <div class="row">
                                <div class="col-md-5 col-sm-12 col-xs-12 p-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>Nit Proveedor</label>
                                        <span class="red">(*)</span>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <input class="form-control" [disabled]="inhabilitar_proveedor || compra.proveedor.id" (keypress)="buscarNit($event,compra.proveedor.nit)" id="compra_nit" name="compra_nit" type="text" [(ngModel)]="compra.proveedor.nit" placeholder="Ingrese el NIT">
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 col-xs-12 p-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12" >
                                        <label>Razón Social Proveedor</label>
                                        <span class="red">(*)</span>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">                                        
                                        <input *ngIf="proveedores_nit.length==0" id="compra_razon_social" name="compra_razon_social" [disabled]="inhabilitar_proveedor || compra.proveedor.id"
                                            [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="establecerProveedor($event)"
                                            type="text" class="form-control" [(ngModel)]="razon_busqueda" 
                                            [ngbTypeahead]="buscarProveedor" placeholder="Buscar proveedor" 
                                            (keypress)="enfocarElemento($event,'compra_id_producto')" autocomplete="off"
                                            />
                                        <select *ngIf="proveedores_nit.length>0" [compareWith]="compararObjectos" name="compra_proveedor" class="form-control" [(ngModel)]="compra.proveedor" (change)="establecerProveedor(compra.proveedor)">
                                            <option [ngValue]="proveedor" *ngFor="let proveedor of proveedores_nit">{{(proveedor.razon_social +'-'+(proveedor.identificacion?proveedor.identificacion:''))}}</option>
                                        </select>

                                        <ng-template #rt let-r="result" let-t="term">
                                            <ngb-highlight [result]="r.razon_social+' '+r.nit" [term]="t"></ngb-highlight>
                                          </ng-template>
                                    </div>
                                </div>
                                <div class="col-md-1 col-sm-12 col-xs-12 p-0">
                                    <div class="col-12 col-sm-12 col-xs-12">
                                        <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" [disabled]="inhabilitar_proveedor" href="javascript:void(0)" *ngIf="compra.proveedor.id" (click)="reiniciarProveedor()">
                                            <i class="fa fa-trash bigger-130 red2"></i>
                                        </a>
                                    </div>
                                    <div class="col-12 col-sm-12 col-xs-12">
                                        <a (click)="abrirPopupRegistroRapidoProveedor()" *ngIf="!compra.proveedor.id" [disabled]="inhabilitar_proveedor" id="creacion_proveedor" href="javascript:void(0)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary">
                                            <i class="fa fa-plus bigger-130"></i>
                                        </a>
                                        <a (click)="abrirPopupRegistroRapidoProveedor()" *ngIf="compra.proveedor.id" [disabled]="inhabilitar_proveedor" id="creacion_proveedor" href="javascript:void(0)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success">
                                            <i class="fa fa-edit bigger-130"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-5 col-sm-12 col-xs-12 pr-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label for="fefin">Fecha </label>&nbsp;
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12 pr-0">
                                        <div class="input-group">
                                            <input id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                                    name="dp" [(ngModel)]="compra.fecha_texto" ngbDatepicker #d="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-12 col-xs-12 pr-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>Tipo de Pago</label>
                                        <span class="red">(*)</span>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <select [compareWith]="compararObjectos" id="compra_pago" name="pago" class="form-control" [(ngModel)]="compra.tipoPago" (change)="cambiarTipoPago()">
                                            <option [ngValue]="tipoPago" *ngFor="let tipoPago of tiposPago">{{tipoPago.nombre}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-12 col-xs-12 p-0" *ngIf="compra.tipoPago.nombre_corto==global_variable.Dictionary.TIPO_PAGO_CRE">
                                    <div class="col-md-12 col-sm-12 col-xs-12" >
                                        <label>Días</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <input name="compra_dias" class="form-control" type="number" [(ngModel)]="compra.dias_credito" />
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="compra.tipoPago.nombre_corto!=global_variable.Dictionary.TIPO_PAGO_CRE">
                                <hr>
                                <div class="row">
                                    <div class="col-md-4 col-sm-12 col-xs-12 p-0" *ngIf="usuario.empresa.usar_caja_general && aplicacion_movimientos_caja.puede_crear">
                                        <ng-container *ngTemplateOutlet="caja"></ng-container>
                                    </div>
                                    <ng-container *ngIf="usuario.empresa.usar_caja_general && compra.tipoPago.nombre_corto==global_variable.Dictionary.TIPO_PAGO_CHEQUE && compra.caja && compra.caja.tipo_caja.nombre_corto==global_variable.Dictionary.TIPO_CAJA_CUENTA_BANCARIA">
                                        <div class="col-md-4 col-sm-12 col-xs-12 p-0" *ngIf="usuario.empresa.usar_caja_general">
                                            <div class="col-md-12 col-sm-12 col-xs-12" >
                                                <label>Cheque</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                <select [compareWith]="compararObjectos" class="form-control" name="cheque" [(ngModel)]="compra.cheque" (change)="establecerChequeBanco()">
                                                    <option [ngValue]="cheque" *ngFor="let cheque of cheques">{{compra.caja.banco.nombre}}-{{compra.caja.numero_cuenta}}-{{cheque.codigo}}-{{cheque.correlativo_actual}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-12 col-xs-12 p-0" *ngIf="usuario.empresa.usar_caja_general">
                                            <div class="col-md-12 col-sm-12 col-xs-12" >
                                                <label>Número Cheque</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                <input name="numero_cheque" class="form-control" [(ngModel)]="compra.numero_cheque" type="number" step="1">
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="compra.tipoPago.nombre_corto==global_variable.Dictionary.TIPO_PAGO_CRE">
                                <hr>
                                <div class="row">
                                    <div class="col-md-4 col-sm-12 col-xs-12 p-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12" >
                                            <label>Total</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <input class="form-control" disabled type="number" step="0.001" [(ngModel)]="compra.total" />
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-12 col-xs-12 p-0" >
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label >A cuenta (Bs):</label>
                                            <span class="red">(*)</span>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <input class="form-control" min="0" name="acuenta" type="number" step="0.001" [(ngModel)]="compra.a_cuenta" value="0.0" (change)="calcularSaldo()"/>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-12 col-xs-12 p-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12" >
                                            <label>Saldo (Bs):</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <input class="form-control" disabled type="number" step="0.001" [(ngModel)]="compra.saldo"/>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <hr [ngClass]="{'d-none':compra.tipoPago.nombre_corto!=global_variable.Dictionary.TIPO_PAGO_CRE}">
                            <div class="row" [ngClass]="{'d-none':compra.tipoPago.nombre_corto!=global_variable.Dictionary.TIPO_PAGO_CRE}">
                                <div class="col-md-5 col-sm-12 col-xs-12 p-0" [ngClass]="{'d-none':(compra.a_cuenta<=0 || !compra.a_cuenta)}">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label class="text-primary p-0">
                                            <i class="fas fa-file-alt"></i> Comprobante Pago : 
                                        </label>  
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <form id="form-pago-comprobante" action="/upload" style="min-height: 0px !important; padding: 0px !important;" class="dropzone bgc-white brc-dark-l3 brc-h-primary-m4 bgc-h-primary-l4 radius-1">
                                            <div class="fallback d-none">
                                                <input name="file" type="file" multiple (change)="subirArchivo($event)"/>
                                            </div>
                            
                                            <div class="dz-default dz-message" style="margin: 0px !important;">
                                                <span class="text-150  text-grey-d2">
                                                <i class="upload-icon fas fa-cloud-upload-alt text-blue-m1 fa-1x mt-4"></i>
                                                </span>
                                            </div>
                                            </form>
                            
                                            <!-- the `html` of this element will be used by dropzone instance for previewing files/images -->
                                            <div id="template-pago-comprobante" class="d-none">
                                            <div class="dz-preview dz-file-preview" style="margin: 0px !important; min-height: 50px !important;">
                                                <div class="dz-image">
                                                <img alt="Archivo" data-dz-thumbnail="" />
                                                </div>
                            
                                                <div class="dz-details">
                                                <div class="dz-size">
                                                    <span data-dz-size=""></span>
                                                </div>
                            
                                                <div class="dz-filename">
                                                    <span data-dz-name=""></span>
                                                </div>
                                                </div>
                            
                                                <div class="dz-progress progress border-1 brc-yellow-tp2" style="height: 0.75rem;">
                                                <div class="progress-bar bgc-success dz-upload " role="progressbar" data-dz-uploadprogress=""></div>
                                                </div>
                            
                                                <div class="dz-error-message">
                                                <span data-dz-errormessage=""></span>
                                                </div>
                            
                                                <div class="dz-success-mark">
                                                <span class="fa-stack fa-lg text-150">
                                                    <i class="fa fa-circle fa-stack-2x text-white"></i>
                                                    <i class="fa fa-check fa-stack-1x fa-inverse text-success-m1"></i>
                                                </span>
                                                </div>
                            
                                                <div class="dz-error-mark">
                                                <span class="fa-stack fa-lg text-150">
                                                    <i class="fa fa-circle fa-stack-2x text-danger-m3"></i>
                                                    <i class="fas fa-exclamation fa-stack-1x fa-inverse text-white"></i>
                                                </span>
                                                </div>
                                            </div>
                                            </div> 
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-12 col-xs-12 p-0" *ngIf="usuario.empresa.usar_caja_general && compra.a_cuenta>0">
                                    <ng-container *ngTemplateOutlet="caja"></ng-container>
                                </div>
                            </div>
                            <hr class="datos-adicionales" style="display:none;">
                            <div class="row">
                                <button type="button" (click)="mostrarOcultarDatosAdicionales()" class="btn btn-xs pos-rel pull-left">
                                    <i class="ace-icon fa fa-angle-double-down bigger-110"></i>
                                </button>
                                <div class="datos-adicionales col-md-12 col-sm-12 col-xs-12 no-padding" style="display:none;">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 col-xs-12 no-padding" *ngIf="configuracion_pagina.datos.opciones_permisos.ver_subida_comprobante_adjunto">
                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                <label class="text-primary p-0">
                                                    <i class="fas fa-file-alt"></i> Documentos Compra : 
                                                </label>  
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                <form id="form-documentos-compra" action="/upload" style="min-height: 0px !important; padding: 0px !important;" class="dropzone bgc-white brc-dark-l3 brc-h-primary-m4 bgc-h-primary-l4 radius-1">
                                                    <div class="fallback d-none">
                                                    <input name="file" type="file" multiple (change)="subirArchivo($event)"/>
                                                    </div>
                                    
                                                    <div class="dz-default dz-message" style="margin: 0px !important;">
                                                    <span class="text-150  text-grey-d2">
                                                    <i class="upload-icon fas fa-cloud-upload-alt text-blue-m1 fa-1x mt-4"></i>
                                                    </span>
                                                    </div>
                                                </form>
                                    
                                                <!-- the `html` of this element will be used by dropzone instance for previewing files/images -->
                                                <div id="template-documentos-compra" class="d-none">
                                                    <div class="dz-preview dz-file-preview" style="margin: 0px !important; min-height: 50px !important;">
                                                    <div class="dz-image">
                                                        <img alt="Archivo" data-dz-thumbnail="" />
                                                    </div>
                                    
                                                    <div class="dz-details">
                                                        <div class="dz-size">
                                                        <span data-dz-size=""></span>
                                                        </div>
                                    
                                                        <div class="dz-filename">
                                                        <span data-dz-name=""></span>
                                                        </div>
                                                    </div>
                                    
                                                    <div class="dz-progress progress border-1 brc-yellow-tp2" style="height: 0.75rem;">
                                                        <div class="progress-bar bgc-success dz-upload " role="progressbar" data-dz-uploadprogress=""></div>
                                                    </div>
                                    
                                                    <div class="dz-error-message">
                                                        <span data-dz-errormessage=""></span>
                                                    </div>
                                    
                                                    <div class="dz-success-mark">
                                                        <span class="fa-stack fa-lg text-150">
                                                        <i class="fa fa-circle fa-stack-2x text-white"></i>
                                                        <i class="fa fa-check fa-stack-1x fa-inverse text-success-m1"></i>
                                                    </span>
                                                    </div>
                                    
                                                    <div class="dz-error-mark">
                                                        <span class="fa-stack fa-lg text-150">
                                                        <i class="fa fa-circle fa-stack-2x text-danger-m3"></i>
                                                        <i class="fas fa-exclamation fa-stack-1x fa-inverse text-white"></i>
                                                    </span>
                                                    </div>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 col-xs-12 no-padding">
                <div class="widget-box" *ngIf="!inhabilitar_busqueda_item">
                    <div class="widget-body">
                        <div class="widget-main">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th *ngIf="!es_almacen" >
                                            <label>CONCEPTO</label>
                                        </th>
                                        <th *ngIf="es_almacen">
                                            <label>PRODUCTO</label>&nbsp;
                                            <button *ngIf="es_almacen && !busqueda_codigo_barra" type="button" (click)="activarDesactivarBusquedaCodigoBarra()" class="btn btn-warning btn-sm">
                                                <i class="ace-icon fa fa-barcode bigger-110"></i>
                                            </button>
                                            <button *ngIf="es_almacen && busqueda_codigo_barra" type="button" (click)="activarDesactivarBusquedaCodigoBarra()" class="btn btn-success btn-sm">
                                                <i class="ace-icon fa fa-gift bigger-110"></i>
                                            </button>
                                            &nbsp;
                                            <button *ngIf="aplicacion_producto.puede_crear" type="button" (click)="agregarNuevoProducto()" class="btn btn-primary btn-sm">
                                                <i class="ace-icon fa fa-plus bigger-110"></i>
                                            </button>
                                        </th>
                                        <th *ngIf="!es_almacen">
                                            <label>DESCRIPCIÓN</label>
                                        </th>
                                        <th *ngIf="es_almacen">
                                            <label>UNID. MED.</label>
                                        </th>
                                        <th *ngIf="usuario.empresa.usar_vencimientos && detalleCompra.producto.activar_vencimiento && detalleCompra.producto.activar_inventario">
                                            <label>FECHA VENC.</label>
                                        </th>
                                        <th *ngIf="usuario.empresa.usar_vencimientos && detalleCompra.producto.activar_vencimiento && detalleCompra.producto.activar_inventario">
                                            <label>LOTE</label>
                                        </th>
                                        <th>
                                            <label>C. U. (Bs/u)</label>
                                        </th>
                                        <th>
                                            <label>CANT. (u)</label>
                                        </th>
                                        <th>
                                            <label>IMP. (Bs)</label>
                                        </th>
                                        <th>
                                            <label>ACC.</label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td *ngIf="!es_almacen" >
                                            <select [disabled]="!compra.sucursal.id" [compareWith]="compararObjectos" id="compra_concepto_egreso" name="compra_concepto_egreso" class="form-control" [(ngModel)]="detalleCompra.centroCosto">
                                                <option [ngValue]="concepto_egreso" *ngFor="let concepto_egreso of conceptos_egreso_filtro | filterBy:{habilitado:true}">{{concepto_egreso.nombre}}</option>
                                            </select>
                                            <span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_CONCEPTO_EGRESOS,'obtenerConceptosEgreso')"></span>
                                        </td>
                                        <td class="p-0" id="gallery" *ngIf="es_almacen">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <input *ngIf="!busqueda_codigo_barra" autocomplete="off" (keypress)="enfocarElemento($event,'compra_cantidad')"
                                                    [disabled]="!compra.almacen || detalleCompra.producto.id" id="compra_id_producto" [(ngModel)]="producto_busqueda"
                                                    [ngbTypeahead]="buscarProducto" placeholder="Buscar producto" [resultTemplate]="rtp" name="producto_busqueda"
                                                    [inputFormatter]="formatterProducto"  (selectItem)="establecerProducto($event)"
                                                    type="text" class="form-control"/>

                                                    <ng-template #rtp let-r="result" let-t="term">
                                                        <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                                                        <ngb-highlight [result]="r.codigo+'-'+r.nombre+'-'+(r.grupo?r.grupo.nombre:'')+'-'+(r.subgrupo?r.subgrupo.nombre:'')" [term]="t"></ngb-highlight>
                                                    </ng-template>

                                                    <a href="javascript:void(0)" *ngIf="detalleCompra.producto && detalleCompra.producto.id" (click)="iniciarDetalleCompra()" class="card-toolbar-btn text-danger-m1">
                                                        <i class="ace-icon fa fa-trash bigger-130"></i>
                                                    </a>
                                                    <input name="busqueda_codigo_barra" class="form-control" *ngIf="busqueda_codigo_barra && (!detalleCompra.producto || !detalleCompra.producto.id)" autocomplete="off" (keypress)="buscarCodigoBarraProducto($event,producto_busqueda)" id="compra_id_producto" required [disabled]="!compra.almacen" type="text" [(ngModel)]="producto_busqueda" placeholder="Esperando al lector..." >
                                                    <input name="busqueda_codigo_barra_d" class="form-control" *ngIf="busqueda_codigo_barra && (detalleCompra.producto && detalleCompra.producto.id)" (keypress)="buscarCodigoBarraProducto($event,producto_busqueda)" required [disabled]="true" type="text" [(ngModel)]="detalleCompra.producto.nombre" placeholder="Esperando al lector..." >
                                                    <hr>                                                 
                                                </div>
                                                <div class="col-md-3" *ngIf="detalleCompra.producto.id">
                                                    <div class="imagen-producto-vista clearfix" *ngIf="detalleCompra.producto && detalleCompra.producto.id">
                                                        <a href="{{rest_server+detalleCompra.producto.imagen}}" data-rel="colorbox" title="{{detalleCompra.producto.nombre}}">
                                                            <img alt="sin imagen" src="{{rest_server+detalleCompra.producto.imagen}}" width="30" height="30" class="d-zoom-3" data-size="1000x700" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="col-md-9" *ngIf="detalleCompra.producto.id && aplicacion_producto.puede_modificar">
                                                    <label *ngIf="usuario.empresa.usar_inventario">¿Activar Inventario?
                                                        <input (change)="actualizarAtributoProducto(detalleCompra.producto,'activar_inventario',detalleCompra.producto.activar_inventario)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="detalleCompra.producto.activar_inventario"/>
                                                    </label>
                                                    <label *ngIf="usuario.empresa.usar_vencimientos">¿Activar Vencimiento?
                                                        <input (change)="actualizarAtributoProducto(detalleCompra.producto,'activar_vencimiento',detalleCompra.producto.activar_vencimiento)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="detalleCompra.producto.activar_vencimiento"/>
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                        <td *ngIf="!es_almacen">
                                            <input autocomplete="off" (keypress)="enfocarElemento($event,((detalleCompra.producto.activar_vencimiento && detalleCompra.producto.activar_inventario)?'compra_fecha_vencimiento':'compra_costo_unitario'))" id="compra_descripcion" name="descripcion" class="form-control" [(ngModel)]="detalleCompra.descripcion" type="text" />
                                        </td>
                                        <td *ngIf="detalleCompra.producto.id && es_almacen">
                                            {{detalleCompra.producto.unidad_medida}}
                                        </td>
                                        <td *ngIf="!detalleCompra.producto.id && es_almacen">
                                            {{detalleCompra.producto.unidad_medida}}
                                        </td>
                                        <td *ngIf="usuario.empresa.usar_vencimientos && detalleCompra.producto.activar_vencimiento && detalleCompra.producto.activar_inventario">
                                            <div class="input-group">
                                                <input id="compra_fecha_vencimiento" name="mov_fecha_vencimiento" class="form-control" placeholder="dd/mm/yyyy"
                                                        name="dp" [(ngModel)]="detalleCompra.fechaVencimientoTexto" ngbDatepicker #dv="ngbDatepicker"
                                                        (keypress)="enfocarElemento($event,'compra_lote')">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="dv.toggle()" type="button"></button>
                                                </div>
                                            </div>
                                        </td>
                                        <td *ngIf="usuario.empresa.usar_vencimientos && detalleCompra.producto.activar_vencimiento && detalleCompra.producto.activar_inventario">
                                            <input id="compra_lote" (keypress)="enfocarElemento($event,'compra_costo_unitario')" type="text" [(ngModel)]="detalleCompra.lote" class="form-control"/>
                                        </td>
                                        <td>
                                            <input #costounitario (focus)="costounitario.select()" id="compra_costo_unitario" (keypress)="enfocarElemento($event,'compra_cantidad')" class="form-control" (keyup)="calcularImporte()"  (change)="calcularImporte()" step="0.001" [(ngModel)]="detalleCompra.costo_unitario" name="costo_unitario" type="number" />
                                        </td>
                                        <td>
                                            <input #cantidad (focus)="cantidad.select()" id="compra_cantidad" (keypress)="enfocarElemento($event,'compra_agregar_detalle_compra')" class="form-control" (keyup)="calcularImporte()" (change)="calcularImporte()" [(ngModel)]="detalleCompra.cantidad" name="cantidad" type="number" />
                                        </td>
                                        <td>
                                            {{detalleCompra.importe}}
                                        </td>
                                        
                                        <td>
                                            <button *ngIf="es_almacen" id="compra_agregar_detalle_compra" type="button" [disabled]="!detalleCompra.producto.id" (click)="agregarDetalleCompra()" class="btn btn-success btn-sm">
                                                <i class="ace-icon fa fa-plus bigger-110"></i>
                                            </button>
                                            <button *ngIf="!es_almacen" id="compra_agregar_detalle_compra" type="button" [disabled]="!detalleCompra.centroCosto.id"  (click)="agregarDetalleCompra()" class="btn btn-success btn-sm">
                                                <i class="ace-icon fa fa-plus bigger-110"></i>
                                            </button>
                                            <hr>
                                            <div class="space-6"></div>
                                            <button id="mostrar_desc" type="button" (click)="mostrarDescuentos()" class="btn btn-info btn-sm">
                                                <i class="ace-icon fa fa-expand-alt bigger-110"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr class="des-datos" style="display:none;">
                                        <th>
                                            <label>DESC. (-)</label>
                                            <label>
                                                <input (change)="calcularImporte()" name="switch-field-1" class="ace-switch input-lg ace-switch-desc bgc-blue-d1" type="checkbox" [(ngModel)]="detalleCompra.tipo_descuento"/>
                                            </label>
                                        </th>
                                        <!--<th>
                                            <label>REC. (+)</label>
                                            <label>
                                                <input class="ace ace-switch ace-switch-4" type="checkbox" [(ngModel)]="compra.detalleCompra.tipo_recargo" [(ngModel)]="compra.recalcular()"/>
                                                <span class="lbl check-compra"></span>
                                            </label>
                                        </th>-->
                                        <th>
                                            <label>ICE (-)</label>
                                        </th>
                                        <!--<th>
                                            <label>EXC. (-)</label>
                                        </th>-->
                                        <th>
                                            <label>Total (Bs)</label>
                                        </th>
                                    </tr>
                                    <tr class="des-datos" style="display:none;">
                                        <td>
                                            <input (keyup)="calcularImporte()" [(ngModel)]="detalleCompra.descuento" step="0.001" class="form-control" type="number" value="0"/>
                                        </td>
                                        <!--<td *ngIf="!compra.descuento_general">
                                            <input [(ngModel)]="compra.calcularImporte()" [(ngModel)]="compra.detalleCompra.recargo" step="0.001" class="col-xs-12" type="number" value="0"/>
                                        </td>-->
                                        <td>
                                            <input (keyup)="calcularImporte()" [(ngModel)]="detalleCompra.ice" step="0.001" class="form-control" type="number" value="0"/>
                                        </td>
                                        <!--<td *ngIf="!compra.descuento_general">
                                            <input [(ngModel)]="compra.calcularImporte()" [(ngModel)]="compra.detalleCompra.excento" step="0.001" class="col-xs-12" type="number" value="0"/>
                                        </td>-->
                                        <td>
                                            {{detalleCompra.total}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="widget-box">
                    <div class="card bgc-dark" *ngIf="compra.detallesCompra.length>0">
                        <div class="card-header card-header-sm">
                            <h5 class="card-title text-110 text-white font-light pt-1">DETALLE DE COMPRA</h5>
                            <div class="card-toolbar">
                                <div class="keep-open btn-group show" title="Columnas">
                                    <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Columns" title="Visualización columnas" aria-expanded="true">
                                        <i class="fa fa-th-list text-orange-d1"></i>
                                        <span class="caret"></span>
                                    </button>
                                
                                    <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionCompraVista.mostrar_producto" (change)="guardarConfiguracionCompraVista()"/>
                                            <span>Nombre</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionCompraVista.mostrar_codigo_producto" (change)="guardarConfiguracionCompraVista()"/>
                                            <span>Cod. Item</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionCompraVista.mostrar_unidad_producto" (change)="guardarConfiguracionCompraVista()"/>
                                            <span>Und. Med.</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionCompraVista.mostrar_costo_unitario" (change)="guardarConfiguracionCompraVista()"/>
                                            <span>C.U. (Bs/u)</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionCompraVista.mostrar_cantidad" (change)="guardarConfiguracionCompraVista()"/>
                                            <span>Cant. (u)</span>
                                        </label>
                                        <label *ngIf="usuario.empresa.usar_vencimientos" class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionCompraVista.mostrar_fecha_vencimiento" (change)="guardarConfiguracionCompraVista()"/>
                                            <span>Venc.</span>
                                        </label>
                                        <label *ngIf="usuario.empresa.usar_vencimientos" class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionCompraVista.mostrar_lote" (change)="guardarConfiguracionCompraVista()"/>
                                            <span>Lote</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionCompraVista.mostrar_importe" (change)="guardarConfiguracionCompraVista()"/>
                                            <span>Imp. (Bs)</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionCompraVista.mostrar_descuento" (change)="guardarConfiguracionCompraVista()"/>
                                            <span>Desc. (-)</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionCompraVista.mostrar_ice" (change)="guardarConfiguracionCompraVista()"/>
                                            <span>Ice (-)</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionCompraVista.mostrar_total" (change)="guardarConfiguracionCompraVista()"/>
                                            <span>Sujeto a Iva</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body bg-white p-0" *ngIf="compra.detallesCompra.length>0">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <label>Nº</label>
                                        </th>
                                        <th *ngIf="!es_almacen">
                                            <label>CONCEPTO</label>
                                        </th>
                                        <th *ngIf="es_almacen && configuracionCompraVista.mostrar_producto">
                                            <label>PRODUCTO</label>
                                        </th>
                                        <th *ngIf="!es_almacen && configuracionCompraVista.mostrar_producto">
                                            <label>DESCRIPCION</label>
                                        </th>
                                        <th *ngIf="es_almacen && configuracionCompraVista.mostrar_codigo_producto">
                                            <label>CÓD. ITEM</label>
                                        </th>
                                        <th *ngIf="es_almacen && configuracionCompraVista.mostrar_unidad_producto">
                                            <label>UNID. MED.</label>
                                        </th>
                                        <th *ngIf="configuracionCompraVista.mostrar_costo_unitario">
                                            <label>C.U. (Bs/u)</label>
                                        </th>
                                        <th *ngIf="configuracionCompraVista.mostrar_cantidad">
                                            <label>CANT. (u)</label>
                                        </th>
                                        <th *ngIf="es_almacen && configuracionCompraVista.mostrar_fecha_vencimiento && compra.usuario.empresa.usar_vencimientos">
                                            <label>VENC</label>
                                        </th>
                                        <th *ngIf="es_almacen && configuracionCompraVista.mostrar_lote && compra.usuario.empresa.usar_vencimientos">
                                            <label>LOTE</label>
                                        </th>
                                        <th *ngIf="configuracionCompraVista.mostrar_importe">
                                            <label>IMP. (Bs)</label>
                                        </th>
                                        <th *ngIf="configuracionCompraVista.mostrar_descuento">
                                            <label>DESC. (-)</label>
                                        </th>
                                        <th>
                                            <label>TOTAL (Bs)</label>
                                        </th>
                                        <th *ngIf="configuracionCompraVista.mostrar_ice">
                                            <label>ICE (-)</label>
                                        </th>
                                        <th *ngIf="usuario.empresa.usar_facturacion && configuracionCompraVista.mostrar_total">
                                            <label>Sujeto a Iva</label>
                                        </th>
                                        <th>
                                            <label>Acciones</label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let detalleCompra of compra.detallesCompra; let i=index;">
                                        <tr  *ngIf="!detalleCompra.eliminado">
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td *ngIf="!es_almacen">
                                                {{detalleCompra.centroCosto.nombre}}
                                            </td>
                                            <td *ngIf="es_almacen && configuracionCompraVista.mostrar_producto">
                                                {{detalleCompra.producto.nombre}}
                                            </td>
                                            <td *ngIf="!es_almacen && configuracionCompraVista.mostrar_producto">
                                                {{detalleCompra.descripcion}}
                                            </td>
                                            <td *ngIf="es_almacen && configuracionCompraVista.mostrar_codigo_producto">
                                                {{detalleCompra.producto.codigo}}
                                            </td>
                                            <td *ngIf="es_almacen && configuracionCompraVista.mostrar_unidad_producto">
                                                {{detalleCompra.producto.unidad_medida}}
                                            </td>
                                            <td *ngIf="configuracionCompraVista.mostrar_costo_unitario">
                                                {{detalleCompra.costo_unitario}}
                                            </td>
                                            <td *ngIf="configuracionCompraVista.mostrar_cantidad">
                                                {{detalleCompra.cantidad}}
                                            </td>
                                            <td *ngIf="configuracionCompraVista.mostrar_fecha_vencimiento && compra.usuario.empresa.usar_vencimientos && es_almacen">
                                                {{detalleCompra.producto.activar_vencimiento?(detalleCompra.fecha_vencimiento | date:"dd/MM/yyyy"):''}}
                                            </td>
                                            <td *ngIf="configuracionCompraVista.mostrar_lote && compra.usuario.empresa.usar_vencimientos && es_almacen">
                                                {{detalleCompra.lote}}
                                            </td>
                                            <td *ngIf="configuracionCompraVista.mostrar_importe">
                                                {{detalleCompra.importe}}
                                            </td>
                                            <td *ngIf="configuracionCompraVista.mostrar_descuento">
                                                <span>{{detalleCompra.descuento}} <i *ngIf="detalleCompra.tipo_descuento" class="fas fa-percent text-primary"></i> <i *ngIf="!detalleCompra.tipo_descuento" class="fas fa-dollar-sign text-primary"></i></span>
                                            </td>
                                            <td>
                                                {{detalleCompra.total}}
                                            </td>
                                            <td *ngIf="configuracionCompraVista.mostrar_ice">
                                                {{detalleCompra.ice}}
                                            </td>
                                            <td *ngIf="usuario.empresa.usar_facturacion && configuracionCompraVista.mostrar_total">
                                                {{detalleCompra.sujeto_a_iva}}
                                            </td>
                                            <td>
                                                <a class="card-toolbar-btn text-danger-m1" href="javascript:void(0)" (click)="eliminarDetalleCompra(detalleCompra)">
                                                    <i class="fa fa-trash bigger-130"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr>
                                        <td>
                                        
                                        </td>
                                        <td *ngIf="configuracionCompraVista.mostrar_producto">
                                            <b>TOTALES</b>
                                        </td>
                                        <td *ngIf="!es_almacen">
                                            
                                        </td>
                                        <td *ngIf="es_almacen && configuracionCompraVista.mostrar_codigo_producto">
                                            
                                        </td>
                                        <td *ngIf="es_almacen && configuracionCompraVista.mostrar_unidad_producto">
                                            
                                        </td>
                                        <td *ngIf="configuracionCompraVista.mostrar_costo_unitario">
                                            
                                        </td>
                                        <td *ngIf="configuracionCompraVista.mostrar_cantidad">
                                            
                                        </td>
                                        <td *ngIf="es_almacen && configuracionCompraVista.mostrar_fecha_vencimiento && compra.usuario.empresa.usar_vencimientos">
                                            
                                        </td>
                                        <td *ngIf=" es_almacen && configuracionCompraVista.mostrar_lote && compra.usuario.empresa.usar_vencimientos">
                                            
                                        </td>
                                        <td *ngIf="configuracionCompraVista.mostrar_importe">
                                            {{compra.importe}}
                                        </td>
                                        <td class="descuento-global" *ngIf="configuracionCompraVista.mostrar_descuento">
                                            <ng-container *ngIf="usuario.empresa.usar_descuento_global_compras">
                                                <input  (change)="establecerDescuentoGlobal()" name="switch-field-1" class="ace-switch input-lg ace-switch-desc bgc-blue-d1" type="checkbox" [(ngModel)]="compra.tipo_descuento"/>
                                                <input class="form-control" type="number" step="0" min="0" [(ngModel)]="compra.descuento" (change)="establecerDescuentoGlobal()" (keyup)="establecerDescuentoGlobal()">  
                                            </ng-container>
                                            <span *ngIf="!usuario.empresa.usar_descuento_global_compras">{{compra.descuento.toFixed(2)}}</span>
                                        </td>
                                        <td *ngIf="configuracionCompraVista.mostrar_recargo">
                                            
                                        </td>
                                        <td class="text-nowrap">
                                            <span class="m-1 bgc-danger-l3 border-1 brc-danger-m3 text-dark-tp3 text-180 px-25 py-3px radius-round">
                                                {{compra.total}}
                                            </span>
                                        </td>
                                        <td *ngIf="configuracionCompraVista.mostrar_ice">
                                            {{compra.ice}}
                                        </td>
                                        <td *ngIf="configuracionCompraVista.mostrar_excento">
                                            
                                        </td>
                                        <td id="total_compra" *ngIf="usuario.empresa.usar_facturacion && configuracionCompraVista.mostrar_total">
                                            {{compra.sujeto_a_iva}}
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">

	<!-- Background of PhotoSwipe. 
It's a separate element as animating opacity is faster than rgba(). -->
	<div class="pswp__bg"></div>

	<!-- Slides wrapper with overflow:hidden. -->
	<div class="pswp__scroll-wrap">

	  <!-- Container that holds slides. 
  PhotoSwipe keeps only 3 of them in the DOM to save memory.
  Don't modify these 3 pswp__item elements, data is added later on. -->
	  <div class="pswp__container">
		<div class="pswp__item"></div>
		<div class="pswp__item"></div>
		<div class="pswp__item"></div>
	  </div>

	  <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
	  <div class="pswp__ui pswp__ui--hidden">

		<div class="pswp__top-bar">

		  <!--  Controls are self-explanatory. Order can be changed. -->

		  <div class="pswp__counter"></div>

		  <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>

		  <button class="pswp__button pswp__button--share" title="Share"></button>

		  <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>

		  <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

		  <!-- Preloader demo http://codepen.io/dimsemenov/pen/yyBWoR -->
		  <!-- element will get class pswp__preloader--active when preloader is running -->
		  <div class="pswp__preloader">
			<div class="pswp__preloader__icn">
			  <div class="pswp__preloader__cut">
				<div class="pswp__preloader__donut"></div>
			  </div>
			</div>
		  </div>
		</div>

		<div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
		  <div class="pswp__share-tooltip"></div>
		</div>

		<button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
		</button>

		<button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
		</button>

		<div class="pswp__caption">
		  <div class="pswp__caption__center"></div>
		</div>

	  </div>

	</div>

  </div>
  
  <ng-template #caja>
    <div class="col-md-12 col-sm-12 col-xs-12" >
        <label>Caja:</label>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12">
        <select (change)="establecerCaja()" [compareWith]="compararObjectos" name="caja" class="form-control" [(ngModel)]="compra.caja">
            <option [ngValue]="caja" *ngFor="let caja of cajas">{{caja.nombre}}-{{caja.moneda.nombre}}</option>
        </select>
    </div>
  </ng-template>