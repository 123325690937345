import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { EstudiantesService } from '../../services/estudiantes/estudiantes.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';
import { GlobalVariable } from 'src/app/global';
import { CarrerasService } from 'src/app/institute/services/carreras/carreras.service';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { EmpresaService } from 'src/app/base/services/empresas/empresa.service';
import { FichaEconomicaEstudianteComponent } from '../ficha-economica-estudiante/ficha-economica-estudiante.component';
import { CursosService } from '../../services/cursos/cursos.service';
import { TutoresComponent } from '../tutores/tutores.component';
import { MigracionInscripcionComponent } from '../migracion-inscripcion/migracion-inscripcion.component';

declare const aplicarWizard:any;
declare const aplicarPluginImagen:any;

@Component({
  selector: 'app-registro-estudiante',
  templateUrl: './registro-estudiante.component.html',
  styleUrls: ['./registro-estudiante.component.css']
})
export class RegistroEstudianteComponent extends BaseComponent implements OnInit {

  tipo_categoria:any={clases:[]};
  tipo_modalidad:any={clases:[]};
  tipo_publicidad:any={clases:[]};
  estudiante:any={persona:{genero:{},usuario:{rol:{}},cliente:{}}};
  generos:any[]=[];
  sin_documentos_identidad:any[]=[];
  inscripcion_pago:any;
  tipo_tipo_discapacidad:any={clases:[]};
  tipo_lenguaje:any={clases:[]};
  tipo_grado_instruccion:any={clases:[]};
  tipo_parentezco:any={clases:[]};
  tipo_nacionalidad:any={clases:[]};
  tipo_hospital:any={clases:[]};
  tipo_asistencia_hospital:any={clases:[]};
  tipo_discapacidad:any={clases:[]};
  tipo_inicio_discapacidad:any={clases:[]};
  tipo_agua_proviene:any={clases:[]};
  tipo_energia_electrica:any={clases:[]};
  tipo_alcantarillado:any={clases:[]};
  tipo_actividad_estudiante:any={clases:[]};
  tipo_salario_actividad:any={clases:[]};
  tipo_acceso_internet:any={clases:[]};
  tipo_frecuencia_acceso_internet:any={clases:[]};
  tipo_llegada_escuela:any={clases:[]};
  tipo_tiempo_llegada_escuela:any={clases:[]};
  tipo_vive_con:any={clases:[]};
  fecha_nacimiento_minimo ={year: new Date().getUTCFullYear()-60,month: 12, day: 31}


  
  idiomas:any[]=[];
  grados_academicos:any[]=[];
  parentescos:any[]=[];

  wizard_edicion_estudiante:string="wizard_edicion_estudiante";
  @Output() onConfirm:EventEmitter<any>  = new EventEmitter<any>();

  registro_inscripcion_instituto_modal:NgbModalRef;
  @ViewChild('registro_inscripcion_instituto_modal')
  private registro_inscripcion_instituto_modal_ref: TemplateRef<any>;
  
  registro_tutor_modal:NgbModalRef;
  @ViewChild('registro_tutor_modal')
  private registro_tutor_modal_ref: TemplateRef<any>;

  registro_inscripcion_colegio_modal:NgbModalRef;
  @ViewChild('registro_inscripcion_colegio_modal')
  private registro_inscripcion_colegio_modal_ref: TemplateRef<any>;

  busqueda_tutor_modal:NgbModalRef;
  migracion_inscripcion_modal:NgbModalRef;

  @ViewChild('ficha_economica_estudiante') ficha_economica_estudiante:FichaEconomicaEstudianteComponent;

  curso:any;
  gestion:any;
  inscripcion:any;
  tutor:any;
  gestiones:any[]=[];
  semestres:any[]=[];
  carreras:any[]=[];
  roles_registro:any[]=[];
  periodos_clase:any[]=[];
  rol_estudiante:any=null;
  rol_tutor:any=null;
  tipo_pertenencia_nacion: any={clases:[]};
  tipo_grado_discapacidad: any={clases:[]};
  tipo_asistencia_centro_salud: any={clases:[]};
  tipo_cantidad_asistencia_centro_salud: any={clases:[]};
  tipo_condicion_vivienda: any={clases:[]};
  tipo_gestion_pasada_trabajo: any={clases:[]};
  tipo_actividad_trabajo: any={clases:[]};
  tipo_turno_trabajo: any={clases:[]};
  tipo_frecuencia_trabajo: any={clases:[]};
  tipo_razon_abandono_escuela: any={clases:[]};
  aplicacion_usuarios:any={puede_crear:false,puede_modificar:false};
  es_rol_profesor:boolean=false;

  constructor(public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public generalService:GeneralService,
    private toastr: ToastrService,
    private estudiantesService:EstudiantesService,
    private carrerasService:CarrerasService,
    public usuarioService:UsuarioService,
    public empresasService:EmpresaService,
    public cursosService:CursosService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  async ngOnInit(): Promise<void> {
    this.filter={
      sucursales:Util.obtenerSucursalesUsuario(this.usuario)
    }
    this.es_rol_profesor=Util.esRolUsuario("PROFESOR",this.usuario);
    if(this.usuario.empresa.usar_cuentas_estudiantes){
      this.aplicacion_usuarios= this.usuario.aplicacionesUsuario.filter(app_usuario => app_usuario.aplicacion.codigo==GlobalVariable.Dictionary.CODIGO_APP_USUARIOS);
      this.aplicacion_usuarios=this.aplicacion_usuarios.length>0?this.aplicacion_usuarios[0]:{puede_crear:false,puede_modificar:false};
      await this.obtenerRoles();
      let rol_estudiante=this.roles_registro.filter(r => r.nombre==GlobalVariable.Dictionary.ROL_ESTUDIANTE);
      if(rol_estudiante.length){
        this.rol_estudiante=rol_estudiante[0];
      }
      let rol_tutor=this.roles_registro.filter(r => r.nombre==GlobalVariable.Dictionary.ROL_TUTOR);
      if(rol_tutor.length){
        this.rol_tutor=rol_tutor[0];
      }
    }
    let entidad_gnr:any=await this.generalService.obtenerClases("GNR").toPromise();
    this.generos=entidad_gnr.clases;
    if(this.estudiante.id){
      await this.obtenerCodigoAutomatico(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_ESTUDIANTE);
      this.estudiante.persona.fecha_nacimiento=this.estudiante.persona.fecha_nacimiento?new Date(this.estudiante.persona.fecha_nacimiento):null;
      this.estudiante.persona.fecha_nacimiento_texto=this.estudiante.persona.fecha_nacimiento?{
        year:this.estudiante.persona.fecha_nacimiento.getFullYear(),
        month:this.estudiante.persona.fecha_nacimiento.getMonth()+1,
        day:this.estudiante.persona.fecha_nacimiento.getDate()
      }:null;
      this.estudiante.persona.usuario=this.estudiante.persona.usuario?this.estudiante.persona.usuario:{rolesUsuario:[],id_sucursal:Util.obtenerSucursalesUsuario(this.usuario)[0].id};
      this.estudiante.persona.usuario.repeticion_clave=this.estudiante.persona.usuario.clave;
      this.estudiante.persona.usuario.rol=this.estudiante.persona.usuario?this.estudiante.persona.usuario.rolesUsuario[0]?this.estudiante.persona.usuario.rolesUsuario[0].rol:this.rol_estudiante:this.rol_estudiante;
      this.estudiante.persona.usuario.aplicacionesUsuario=await this.buscarAplicacionesRol(this.estudiante.persona.usuario.rol);
      this.estudiante.codigo_nuevo_asignado=this.estudiante.codigo?false:true;
      this.estudiante.codigo=this.estudiante.codigo?this.estudiante.codigo:await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_ESTUDIANTE);
      for(let i=0;i<this.estudiante.tutores.length;i++){
        this.estudiante.tutores[i].persona.usuario=this.estudiante.tutores[i].persona.usuario?this.estudiante.tutores[i].persona.usuario:{rolesUsuario:[],id_sucursal:Util.obtenerSucursalesUsuario(this.usuario)[0].id};
        this.estudiante.tutores[i].persona.usuario.repeticion_clave=this.estudiante.tutores[i].persona.usuario.clave;
        this.estudiante.tutores[i].persona.usuario.rol=this.estudiante.tutores[i].persona.usuario?this.estudiante.tutores[i].persona.usuario.rolesUsuario[0]?this.estudiante.tutores[i].persona.usuario.rolesUsuario[0].rol:this.rol_tutor:this.rol_tutor;
      }
    }else{
      let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_ESTUDIANTE);
      this.estudiante={
         codigo:codigo_nuevo,
         persona:{
           activo:true,
           genero:{},
           imagen:"img/icon-user-default.png",
           usuario:{rol:this.rol_estudiante,cierre_sesion_automatico:true,activo:true,multidispositivo:false,enviar_correo:false,id_sucursal:Util.obtenerSucursalesUsuario(this.usuario)[0].id},cliente:{nit:0}},
         tutores:[],
         inscripciones_instituto:[],
         inscripciones_colegio:[]
      }

      if(this.estudiante.persona.usuario.rol){
        this.estudiante.persona.usuario.id_rol=this.estudiante.persona.usuario.rol?this.estudiante.persona.usuario.rol.id:null;
        this.estudiante.persona.usuario.aplicacionesUsuario=await this.buscarAplicacionesRol(this.estudiante.persona.usuario.rol);
      }
      this.estudiante.sucursal=this.filter.sucursales[0];
    }
    this.obtenerCategoriasEstudiantesEmpresa();
    this.obtenerPublicidadInscripcionEmpresa();
    this.obtenerModalidadInscripcionEmpresa();
    this.obtenerPeriodosClase();
    this.obtenerTiposDocumentosIdentidad();
    this.tipo_vive_con=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.TIPO_ESTUDIANTE_VIVE_CON).toPromise();
    if(this.usuario.empresa.usar_hoja_rude){
      await this.obtenerClasesRude();
      this.estudiante.presenta_discapacidad=this.obtenerValorPorDefecto(this.estudiante.presenta_discapacidad,false,[],false);
      this.estudiante.tipo_discapacidad=this.obtenerValorPorDefecto(this.estudiante.tipo_discapacidad,true,this.tipo_tipo_discapacidad.clases,"NINGUNA");
      this.estudiante.grado_discapacidad=this.obtenerValorPorDefecto(this.estudiante.grado_discapacidad,true,this.tipo_grado_discapacidad.clases,"NINGUNA");
      this.estudiante.idioma_infancia=this.obtenerValorPorDefecto(this.estudiante.idioma_infancia,false,[],"CASTELLANO");
      this.estudiante.idiomas_frecuentes=this.obtenerValorPorDefecto(this.estudiante.idiomas_frecuentes,false,[],"CASTELLANO");
      this.estudiante.nacion_pertenencia=this.obtenerValorPorDefecto(this.estudiante.nacion_pertenencia,true,this.tipo_pertenencia_nacion.clases,"NINGUNA");
      this.estudiante.existe_centro_salud_comunidad=this.obtenerValorPorDefecto(this.estudiante.existe_centro_salud_comunidad,false,[],true);
      this.estudiante.asistencia_centro_salud=this.obtenerValorPorDefecto(this.estudiante.asistencia_centro_salud,true,this.tipo_asistencia_centro_salud.clases,"NINGUNA");
      this.estudiante.cantidad_asistencia_centro_salud=this.obtenerValorPorDefecto(this.estudiante.cantidad_asistencia_centro_salud,true,this.tipo_cantidad_asistencia_centro_salud.clases,"NINGUNA");
      this.estudiante.tiene_seguro_salud=this.obtenerValorPorDefecto(this.estudiante.tiene_seguro_salud,false,[],false);
      this.estudiante.tiene_acceso_agua_red=this.obtenerValorPorDefecto(this.estudiante.tiene_acceso_agua_red,false,[],true);
      this.estudiante.tiene_alcantarillado=this.obtenerValorPorDefecto(this.estudiante.tiene_alcantarillado,false,[],true);
      this.estudiante.tiene_bano_vivienda=this.obtenerValorPorDefecto(this.estudiante.tiene_bano_vivienda,false,[],true);
      this.estudiante.tiene_energia_electrica=this.obtenerValorPorDefecto(this.estudiante.tiene_energia_electrica,false,[],true);
      this.estudiante.tiene_servicio_recojo_basura=this.obtenerValorPorDefecto(this.estudiante.tiene_servicio_recojo_basura,false,[],true);
      this.estudiante.condicion_vivienda=this.obtenerValorPorDefecto(this.estudiante.condicion_vivienda,true,this.tipo_condicion_vivienda.clases,"NINGUNA");
      this.estudiante.acceso_internet=this.obtenerValorPorDefecto(this.estudiante.acceso_internet,true,this.tipo_acceso_internet.clases,"NINGUNA");
      this.estudiante.frecuencia_acceso_internet=this.obtenerValorPorDefecto(this.estudiante.frecuencia_acceso_internet,true,this.tipo_frecuencia_acceso_internet.clases,"NINGUNA");
      this.estudiante.gestion_pasada_trabajo=this.obtenerValorPorDefecto(this.estudiante.gestion_pasada_trabajo,false,[],false);
      this.estudiante.actividad_trabajo=this.obtenerValorPorDefecto(this.estudiante.actividad_trabajo,true,this.tipo_actividad_trabajo.clases,"NINGUNA");
      this.estudiante.turno_trabajo=this.obtenerValorPorDefecto(this.estudiante.turno_trabajo,true,this.tipo_turno_trabajo.clases,"NINGUNA");
      this.estudiante.frecuencia_trabajo=this.obtenerValorPorDefecto(this.estudiante.frecuencia_trabajo,true,this.tipo_frecuencia_trabajo.clases,"NINGUNA");
      this.estudiante.recibio_pago_trabajo=this.obtenerValorPorDefecto(this.estudiante.recibio_pago_trabajo,false,[],false);
      this.estudiante.llegada_escuela=this.obtenerValorPorDefecto(this.estudiante.llegada_escuela,true,this.tipo_llegada_escuela.clases,"NINGUNA");
      this.estudiante.tiempo_llegada_escuela=this.obtenerValorPorDefecto(this.estudiante.tiempo_llegada_escuela,true,this.tipo_tiempo_llegada_escuela.clases,"NINGUNA");
      this.estudiante.abandono_escuela=this.obtenerValorPorDefecto(this.estudiante.abandono_escuela,false,[],false);
      this.estudiante.razon_abandono_escuela=this.obtenerValorPorDefecto(this.estudiante.razon_abandono_escuela,true,this.tipo_razon_abandono_escuela.clases,"NINGUNA");

      this.estudiante.persona.pais_nacimiento=this.obtenerValorPorDefecto(this.estudiante.persona.pais_nacimiento,false,[],"BOLIVIA");
      this.estudiante.persona.ciudad_nacimiento=this.obtenerValorPorDefecto(this.estudiante.persona.ciudad_nacimiento,false,[],"COCHABAMBA");
      this.estudiante.persona.provincia_nacimiento=this.obtenerValorPorDefecto(this.estudiante.persona.provincia_nacimiento,false,[],"CERCADO");
      this.estudiante.persona.localidad_nacimiento=this.obtenerValorPorDefecto(this.estudiante.persona.localidad_nacimiento,false,[],"CERCADO");

      this.estudiante.persona.direccion_ciudad=this.obtenerValorPorDefecto(this.estudiante.persona.direccion_ciudad,false,[],"COCHABAMBA");
      this.estudiante.persona.direccion_provincia=this.obtenerValorPorDefecto(this.estudiante.persona.direccion_provincia,false,[],"CERCADO");
      this.estudiante.persona.direccion_municipio=this.obtenerValorPorDefecto(this.estudiante.persona.direccion_municipio,false,[],"CERCADO");
      this.estudiante.persona.direccion_localidad=this.obtenerValorPorDefecto(this.estudiante.persona.direccion_localidad,false,[],"CERCADO");

    }
  }

  ngAfterViewInit() {
    aplicarWizard(this.wizard_edicion_estudiante,this,'guardar');
	  aplicarPluginImagen();
  }

  obtenerGestiones(){
    this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_GESTIONES).subscribe((entidad:any)=>{
      this.gestiones=entidad.clases
    });
  }

  obtenerSemestresGestion(){
    this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_SEMESTRES_GESTION).subscribe((entidad:any)=>{
      this.semestres=entidad.clases
    });
  }

  obtenerCarreras(){
    this.carrerasService.obtenerCarreras(this.filter.escuela.id).subscribe((carreras:any)=>{
      this.carreras=carreras;
    });
  }

  obtenerValorPorDefecto(valor_modelo,es_lista,items,valor_por_defecto){
		if(valor_modelo==null){
			if(es_lista){
        valor_por_defecto = items.filter(e => e.nombre == valor_por_defecto)[0];
			}
			valor_modelo=valor_por_defecto;
		}
		return valor_modelo;
  }

  async obtenerClasesRude(){
    this.tipo_tipo_discapacidad=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.RUDE.TIPO_DISCAPACIDAD).toPromise();
    this.tipo_grado_discapacidad=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.RUDE.TIPO_GRADO_DISCAPACIDAD).toPromise();
    this.tipo_pertenencia_nacion=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.RUDE.TIPO_PERTENENCIA_NACION).toPromise();
    this.tipo_asistencia_centro_salud=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.RUDE.TIPO_ASISTENCIA_CENTRO_SALUD).toPromise();
    this.tipo_cantidad_asistencia_centro_salud=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.RUDE.TIPO_CANTIDAD_ASISTENCIA_CENTRO_SALUD).toPromise();
    this.tipo_condicion_vivienda=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.RUDE.TIPO_CONDICION_VIVIENDA).toPromise();
    this.tipo_acceso_internet=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.RUDE.TIPO_ACCESO_INTERNET).toPromise();
    this.tipo_frecuencia_acceso_internet=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.RUDE.TIPO_FRECUENCIA_ACCESO_INTERNET).toPromise();
    this.tipo_gestion_pasada_trabajo=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.RUDE.TIPO_ACTIVIDAD_TRABAJO).toPromise();
    this.tipo_actividad_trabajo=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.RUDE.TIPO_TURNO_TRABAJO).toPromise();
    this.tipo_turno_trabajo=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.RUDE.TIPO_FRECUENCIA_TRABAJO).toPromise();
    this.tipo_frecuencia_trabajo=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.RUDE.TIPO_FRECUENCIA_TRABAJO).toPromise();
    this.tipo_llegada_escuela=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.RUDE.TIPO_LLEGADA_ESCUELA).toPromise();
    this.tipo_tiempo_llegada_escuela=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.RUDE.TIPO_TIEMPO_LLEGADA_ESCUELA).toPromise();
    this.tipo_razon_abandono_escuela=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.RUDE.TIPO_RAZON_ABANDONO_ESCUELA).toPromise();
  }

  establecerValoresPorDefecto(establecer_valores_para_listas){
		/*this.person.birthplace_country=this.obtenerValorPorDefecto(this.person.birthplace_country,"BOLIVIA",false,null,null);
    var defaultValue=this.default_values.filter(e => e.short_name == 'CIUDAD_NACIMIENTO');
		defaultValue=defaultValue.length>0?defaultValue[0].name:"";
		this.person.birthplace_city=this.getDefaultValue(this.person.birthplace_city,defaultValue,false,null,null);
		defaultValue=this.default_values.filter(e => e.short_name == 'PROVINCIA_NACIMIENTO');
		defaultValue=defaultValue.length>0?defaultValue[0].name:"";
		this.person.birthplace_province=this.getDefaultValue(this.person.birthplace_province,defaultValue,false,null,null);
		defaultValue=this.default_values.filter(e => e.short_name == 'LOCALIDAD_NACIMIENTO');
		defaultValue=defaultValue.length>0?defaultValue[0].name:"";
		this.person.birthplace_locality=this.getDefaultValue(this.person.birthplace_locality,defaultValue,false,null,null);
		defaultValue=this.default_values.filter(e => e.short_name == 'PROVINCIA_DIRECCION');
		defaultValue=defaultValue.length>0?defaultValue[0].name:"";
		this.person.province_address=this.getDefaultValue(this.person.province_address,defaultValue,false,null,null);
		defaultValue=this.default_values.filter(e => e.short_name == 'CIUDAD_DIRECCION');
		defaultValue=defaultValue.length>0?defaultValue[0].name:"";
		this.person.city_address=this.getDefaultValue(this.person.city_address,defaultValue,false,null,null);
		defaultValue=this.default_values.filter(e => e.short_name == 'LOCALIDAD_DIRECCION');
		defaultValue=defaultValue.length>0?defaultValue[0].name:"";
		this.person.locality_address=this.getDefaultValue(this.person.locality_address,defaultValue,false,null,null);
		if(establecer_valores_para_listas){
			this.person.student.infancy_language_id=this.getDefaultValue(this.person.student.infancy_language_id,null,true,this.languages,"CASTELLANO");
			this.person.language_id=this.getDefaultValue(this.person.language_id,null,true,this.languages,"CASTELLANO");
			this.person.student.nation_belong_id=this.getDefaultValue(this.person.student.nation_belong_id,null,true,this.nation.instances,"NO PERTENECE");
			this.person.student.hospital_existence_id=this.getDefaultValue(this.person.student.hospital_existence_id,null,true,this.hospital.instances,"SÍ");
			this.person.student.hospital_attendance_number_id=this.getDefaultValue(this.person.student.hospital_attendance_number_id,null,true,this.hospitalAttendance.instances,"NINGUNA");
			this.person.student.disability_id=this.getDefaultValue(this.person.student.disability_id,null,true,this.disability.instances,"NINGUNA");
			this.person.student.disability_beginning_id=this.getDefaultValue(this.person.student.disability_beginning_id,null,true,this.disabilityBeginning.instances,"NINGUNA");
			this.person.student.water_come_id=this.getDefaultValue(this.person.student.water_come_id,null,true,this.waterCome.instances,"CAÑERIA DE RED");
			this.person.student.electrical_energy_id=this.getDefaultValue(this.person.student.electrical_energy_id,null,true,this.electricalEnergy.instances,"SÍ");
			this.person.student.drainage_id=this.getDefaultValue(this.person.student.drainage_id,null,true,this.drainage.instances,"ALCANTARILLADO");
			this.person.student.student_activity_id=this.getDefaultValue(this.person.student.student_activity_id,null,true,this.studentActivity.instances,"NO TRABAJÓ");
			this.person.student.salary_activity_id=this.getDefaultValue(this.person.student.salary_activity_id,null,true,this.salaryActivity.instances,"NO");
			this.person.student.internet_access_id=this.getDefaultValue(this.person.student.internet_access_id,null,true,this.internetAccess.instances,"EN LA UNIDAD EDUCATIVA");
			this.person.student.internet_access_frecuency_id=this.getDefaultValue(this.person.student.internet_access_frecuency_id,null,true,this.internetAccessFrecuency.instances,"DIARIAMENTE");
			this.person.student.school_arrive_id=this.getDefaultValue(this.person.student.school_arrive_id,null,true,this.schoolArrive.instances,"EN VEHÍCULO DE TRANSPORTE TERRESTRE");
			this.person.student.school_arrive_time_id=this.getDefaultValue(this.person.student.school_arrive_time_id,null,true,this.schoolArriveTime.instances,"ENTRE MEDIA HORA Y UNA HORA");			
		}*/
  }

  subirImagen(e){
		let me=this;
		let filesSelected =  e.target.files;
		if (filesSelected.length > 0)
		{
			var fileToLoad = filesSelected[0];
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
				me.estudiante.persona.imagen=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
		}
  }

  cerrarRegistroEstudiante(){
    this.onConfirm.emit({confirm:false});
  }

  async guardar(){
    this.blockUI.start();
		if(await this.validarFormularioRegistroEstudiante()){
      this.estudiante.persona.fecha_nacimiento=this.estudiante.persona.fecha_nacimiento_texto?Util.convertirObjetoAfecha(this.estudiante.persona.fecha_nacimiento_texto):null;
      this.estudiante.id_empresa=this.usuario.id_empresa;
			if(this.estudiante.id){
				this.estudiantesService.actualizarEstudiante(this.estudiante).subscribe((res:any) => {
          this.blockUI.stop();
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
          }else{
            if(this.estudiante.codigo_nuevo_asignado){
              this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_ESTUDIANTE);
            }
            this.toastr.success(res.mensaje);
					  this.onConfirm.emit({confirm:true});
          }
				});
			}else{
        this.estudiante.id_gestion=this.gestion?this.gestion.id:null;
        this.estudiante.id_curso=this.curso?this.curso.id:null;
        this.estudiante.codigo=this.codigo_automatico?await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_ESTUDIANTE):this.estudiante.codigo;
				this.estudiantesService.guardarEstudiante(this.estudiante).subscribe((res:any) => {
          this.blockUI.stop();
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
          }else{
            this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_ESTUDIANTE);
            this.toastr.success(res.mensaje);
					  this.onConfirm.emit({confirm:true});
          }
				});
			}
		}else{
      this.blockUI.stop();
    }
  }
  
  async validarFormularioRegistroEstudiante(){
		let res=true;
    if(!this.estudiante.codigo){
			this.toastr.error("Debe ingresar el código del estudiante!");
			res=false;
		}
		if(!this.estudiante.persona.nombres){
			this.toastr.error("Debe ingresar el nombre del estudiante!");
			res=false;
		}
		if(!this.estudiante.persona.genero || !this.estudiante.persona.genero.id){
			this.toastr.error("Debe ingresar el género del estudiante!");
			res=false;
		}
    /*if(!this.estudiante.categoria_1){
			this.toastr.error("Debe ingresar la categoria del estudiante!");
			res=false;
		}*/
    if(this.usuario.empresa.usar_cuentas_estudiantes && this.estudiante.persona.usuario.nombre_usuario){
			res = res && (await this.validarUsuario(this.estudiante.persona.usuario));
		  res = res && (await this.verificarClaveRepeticion(this.estudiante.persona.usuario));

      if(!this.estudiante.persona.usuario.rol || !this.estudiante.persona.usuario.rol.id){
        this.toastr.error("Debe ingresar el rol de usuario!");
        res=false;
      }
		}
		return res;
	}

  async crearNuevaInscripcionInstituto(){
    let fecha_actual=new Date();
    this.inscripcion={
      activo:true,
      id_usuario_creador:this.usuario.id,
      fecha_inicio_texto:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      }
    }
    this.inscripcion_pago=this.inscripcion;
    this.filter={};
    await this.obtenerEscuela();
    this.obtenerGestiones();
    this.obtenerSemestresGestion();
    this.obtenerCarreras();
		this.abrirRegistroInscripcionInstituto();
	}

  abrirRegistroInscripcionInstituto(){
    this.registro_inscripcion_instituto_modal=this.modalService.open(this.registro_inscripcion_instituto_modal_ref, {scrollable:true, windowClass:'inscripcion_instituto',ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.registro_inscripcion_instituto_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  agregarInscripcionInstituto(){
    if(this.validarInscripcionInstituto()){
      this.inscripcion.fecha_inicio=this.inscripcion.fecha_inicio_texto?Util.convertirObjetoAfecha(this.inscripcion.fecha_inicio_texto):null;
      this.estudiante.inscripciones_instituto.push(this.inscripcion);
      this.registro_inscripcion_instituto_modal.close();
    }
  }

  validarInscripcionInstituto(){
    let res=true;
		if(!this.inscripcion.carrera){
      res=res && false;
      this.toastr.error("Debe especificar la carrera!");
    }
		return res;
  }

  abrirPopupConfirmacionEliminacion(inscripcion){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de anular la inscripción?";
		this.popupConfirmacion.componentInstance.data = inscripcion;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.anularInscripcion($e.data);
			}
			this.popupConfirmacion.close();
    });
  }

  anularInscripcion(inscripcion){
    if(inscripcion.id){
      inscripcion.activo=false;
    }else{
      inscripcion.eliminado=true;
    }
  }

  abrirPopupConfirmacionEliminacionInscripcion(inscripcion){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar la inscripción?";
		this.popupConfirmacion.componentInstance.data = inscripcion;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarInscripcion($e.data);
			}
			this.popupConfirmacion.close();
    });
  }

  eliminarInscripcion(inscripcion){
    inscripcion.eliminado=true;
  }

  async validarUsuario(usuario) {
		var res=true;
    let existencia_usuario:any=await this.usuarioService.verificarUsuario(usuario.id?usuario.id:0,usuario.nombre_usuario).toPromise();
    if(existencia_usuario.usuario){
      res=false;
      this.toastr.error("Ya existe una cuenta con el correo electronico ingresado, intente nuevamente!");
    }
    
    if(!usuario.clave){
			this.toastr.error("Debe ingresar una clave!");
			res=false;
		}
		if(!usuario.repeticion_clave){
			this.toastr.error("Debe ingresar la repetición de la clave!");
			res=false;
		}
    return res;
	}

  async verificarClaveRepeticion(usuario){
		var res=true;
		if(usuario.clave!=usuario.repeticion_clave){
			res=false;
			this.toastr.error("Las claves ingresadas no coinciden! Vuelva a intentarlo.");
		}
		return res;
  }

  async obtenerRoles(){
    this.blockUI.start();
    if(this.usuario.empresa.usar_aplicaciones_por_rol){
      let roles:any=await this.empresasService.obtenerRolesEmpresa(this.usuario.empresa.id).toPromise();
      this.roles_registro=roles.filter(r => (r.nombre.toUpperCase()==GlobalVariable.Dictionary.ROL_ESTUDIANTE) || (r.nombre.toUpperCase()==GlobalVariable.Dictionary.ROL_TUTOR));
    }else{
      let roles:any=await this.empresasService.obtenerRoles().toPromise();
      this.roles_registro=roles.filter(r => (r.nombre.toUpperCase()==GlobalVariable.Dictionary.ROL_ESTUDIANTE) || (r.nombre.toUpperCase()==GlobalVariable.Dictionary.ROL_TUTOR));
    }
    this.blockUI.stop();
  }

  async buscarAplicacionesRol(rol_a_buscar){
    let aplicacionesUsuario=[];
    if(this.usuario.empresa.usar_aplicaciones_por_rol && rol_a_buscar){
      let rol:any= await this.empresasService.obtenerRol(rol_a_buscar.id).toPromise();
      for(var j=0;j<rol.aplicacionesRol.length;j++){
        if(rol.aplicacionesRol[j].habilitado){
          aplicacionesUsuario.push({id_aplicacion:rol.aplicacionesRol[j].aplicacion.id,
            aplicacion:rol.aplicacionesRol[j].aplicacion,
            puede_crear:true,
            puede_ver:true,
            puede_modificar:true,
            puede_eliminar:true});
        }
      }
    }
    return aplicacionesUsuario;
  }

  async establecerAplicacionesUsuario(rol_a_buscar){
    this.estudiante.persona.usuario.aplicacionesUsuario=await this.buscarAplicacionesRol(rol_a_buscar);
  }

  async obtenerCategoriasEstudiantesEmpresa(){
		this.tipo_categoria=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_ESTUDIANTE_CATEGORIAS).toPromise();
	}

  async obtenerPublicidadInscripcionEmpresa(){
		this.tipo_publicidad=await this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_INSCRIPCION_PUBLICIDAD).toPromise();
	}

  async obtenerModalidadInscripcionEmpresa(){
		this.tipo_modalidad=await this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_INSCRIPCION_MODALIDADES).toPromise();
	}

  async obtenerIdiomas(){
		let idiomas:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.TIPO_IDIOMA).toPromise();
    this.idiomas=idiomas.clases;
	}

  async obtenerGradosAcademicos(){
		let grados:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.TIPO_GRADO_INSTRUCCION).toPromise();
    this.grados_academicos=grados.clases;
	}

  async obtenerParentescos(){
		let parentescos:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.TIPO_PARENTESCO).toPromise();
    this.parentescos=parentescos.clases;
	}

  async obtenerPeriodosClase(){
    let turnos:any=await this.carrerasService.obtenerListaPeriodosClase(this.usuario.id_empresa).toPromise();
    this.periodos_clase=turnos;
  }

  async crearNuevaInscripcionColegio(){
    let fecha_actual=new Date();
    this.inscripcion={
      activo:true,
      consolidado:false,
      id_usuario_creador:this.usuario.id,
      fecha_inicio_texto:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      }
    }
    this.inscripcion_pago=this.inscripcion;
    this.filter={};
    await this.obtenerEscuela();
    this.obtenerGestiones();
    this.obtenerNivelesEscolares();
		this.abrirRegistroInscripcionColegio();
	}

  async modificarInscripcionColegio(inscripcion){
    this.inscripcion=inscripcion
    this.filter={};
    await this.obtenerEscuela();
    await this.obtenerGestiones();
    await this.obtenerNivelesEscolares();
		await this.abrirRegistroInscripcionColegio();
    this.inscripcion.nivel=this.filter.niveles_escolares.filter(n => n.id==this.inscripcion.curso.id_nivel)[0];
    this.obtenerCursosPorNivel(this.inscripcion.nivel.id)
	}

  abrirRegistroInscripcionColegio(){
    this.registro_inscripcion_colegio_modal=this.modalService.open(this.registro_inscripcion_colegio_modal_ref, {scrollable:true, windowClass:'inscripcion_instituto',ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.registro_inscripcion_colegio_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  agregarInscripcionColegio(){
    if(this.validarInscripcionColegio()){
      this.inscripcion.fecha_inicio=this.inscripcion.fecha_inicio_texto?Util.convertirObjetoAfecha(this.inscripcion.fecha_inicio_texto):null;
      if(this.inscripcion.id){
        //this.estudiante.inscripciones_colegio.push(this.inscripcion);
      }else{
        this.estudiante.inscripciones_colegio.push(this.inscripcion);
      }
      this.registro_inscripcion_colegio_modal.close();
    }
  }

  validarInscripcionColegio(){
    let res=true;
		if(!this.inscripcion.gestion){
      res=res && false;
      this.toastr.error("Debe especificar la gestión!");
    }
    if(!this.inscripcion.curso){
      res=res && false;
      this.toastr.error("Debe especificar el curso!");
    }
		return res;
  }

  async obtenerNivelesEscolares(){
    let niveles_escolares:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.SCH.TIPO_NIVEL_ESCOLAR).toPromise();
    this.filter.niveles_escolares=niveles_escolares.clases;
  }

  async obtenerCursosPorNivel(id_nivel_escolar){
    let cursos:any=await this.cursosService.obtenerCursosNivel(this.usuario.id_empresa,id_nivel_escolar).toPromise();
    this.filter.cursos=cursos;
  }

  async crearNuevoRegistroTutor(){
    this.tutor={
      activo:true,
      tutor_principal:false,
      persona:{
        usuario:{
          rol:this.rol_tutor,
          id_sucursal:Util.obtenerSucursalesUsuario(this.usuario)[0].id
        }
      }
    }
    if(this.tutor.persona.usuario.rol){
      this.tutor.persona.usuario.id_rol=this.tutor.persona.usuario.rol?this.tutor.persona.usuario.rol.id:null;
      this.tutor.persona.usuario.aplicacionesUsuario=await this.buscarAplicacionesRol(this.tutor.persona.usuario.rol);
    }
    this.obtenerIdiomas();
    this.obtenerParentescos();
    this.obtenerGradosAcademicos();
    this.abrirModalRegistroTutor();
	}

  abrirModalRegistroTutor(){
    this.registro_tutor_modal=this.modalService.open(this.registro_tutor_modal_ref, {windowClass:'registro_tutor_modal', scrollable:true,ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.registro_tutor_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async agregarTutor(){
    if(await this.validarRegistroTutor()){
      if(!this.tutor.id){
        if(this.estudiante.tutores.indexOf(this.tutor)==-1){
          this.estudiante.tutores.push(this.tutor);    
        }
      }
      this.tutor.persona.fecha_nacimiento=this.tutor.persona.fecha_nacimiento_texto?Util.convertirObjetoAfecha(this.tutor.persona.fecha_nacimiento_texto):null;
      this.registro_tutor_modal.close();
    }
  }

  async validarRegistroTutor(){
    let res=true;
    if(this.usuario.empresa.usar_cuentas_estudiantes && this.tutor.persona.usuario && this.tutor.persona.usuario.nombre_usuario){
			res=res && (await this.verificarClaveRepeticion(this.tutor.persona.usuario)) && (await this.validarUsuario(this.tutor.persona.usuario));
      if(!this.tutor.persona.usuario.rol || !this.tutor.persona.usuario.rol.id){
        this.toastr.error("Debe ingresar el rol de usuario del tutor!");
        res=false;
      }
		}
		if(!this.tutor.persona.nombres){
      res=res && false;
      this.toastr.error("Debe especificar el nombre del tutor!");
    }
    if(!this.tutor.persona.lenguaje){
      res=res && false;
      this.toastr.error("Debe especificar el lenguaje que habla el tutor!");
    }
    if(!this.tutor.persona.grado){
      res=res && false;
      this.toastr.error("Debe especificar el grado academico del tutor!");
    }
    if(!this.tutor.parentesco){
      res=res && false;
      this.toastr.error("Debe especificar la relacion de parentesco del tutor!");
    }
		return res;
  }

  abrirPopupConfirmacionEliminacionTutor(tutor){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar al tutor?";
		this.popupConfirmacion.componentInstance.data = tutor;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarTutor($e.data);
			}
			this.popupConfirmacion.close();
    });
  }

  eliminarTutor(tutor){
    if(tutor.id){
			tutor.eliminado=true;
      tutor.activo=false;
			tutor.persona.activo=false;
		}else{
			this.estudiante.tutores.splice(this.estudiante.tutores.indexOf(tutor),1);
		}
		this.toastr.info("Tutor removido");
  }

  async modificarTutor(tutor){
    this.tutor=tutor;
    if(this.tutor.persona.usuario){
      if(this.tutor.persona.usuario.rol){
        this.tutor.persona.usuario.id_rol=this.tutor.persona.usuario.rol?this.tutor.persona.usuario.rol.id:null;
        this.tutor.persona.usuario.aplicacionesUsuario=await this.buscarAplicacionesRol(this.tutor.persona.usuario.rol);
      }
    }else{
      this.tutor.persona.usuario={
        rol:this.rol_tutor,
        id_sucursal:Util.obtenerSucursalesUsuario(this.usuario)[0].id
      };
      if(this.tutor.persona.usuario.rol){
        this.tutor.persona.usuario.id_rol=this.tutor.persona.usuario.rol?this.tutor.persona.usuario.rol.id:null;
        this.tutor.persona.usuario.aplicacionesUsuario=await this.buscarAplicacionesRol(this.tutor.persona.usuario.rol);
      }
    }
    this.tutor.persona.fecha_nacimiento=this.tutor.persona.fecha_nacimiento?new Date(this.tutor.persona.fecha_nacimiento):null;
    this.tutor.persona.fecha_nacimiento_texto=this.tutor.persona.fecha_nacimiento?{
      year:this.tutor.persona.fecha_nacimiento.getFullYear(),
      month:this.tutor.persona.fecha_nacimiento.getMonth()+1,
      day:this.tutor.persona.fecha_nacimiento.getDate()
    }:null;
    this.obtenerIdiomas();
    this.obtenerParentescos();
    this.obtenerGradosAcademicos();
    this.abrirModalRegistroTutor();
  }

  async abrirModalBusquedaTutor(){
    this.filter={};
    await this.obtenerEscuela();
      this.busqueda_tutor_modal = this.modalService.open(TutoresComponent, {windowClass:'lista-tutores', ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
      this.busqueda_tutor_modal.componentInstance.escuela = this.filter.escuela; 
      this.busqueda_tutor_modal.componentInstance.alTerminar.subscribe((persona_seleccionado) => {
        if(persona_seleccionado){
          this.seleccionarTutor(persona_seleccionado);
        }else{
          this.busqueda_tutor_modal.close();
        }
      });
  }

  async seleccionarTutor(persona_seleccionado){
		let existe_tutor=this.estudiante.tutores.filter(e => e.id_persona==persona_seleccionado.id);
		if(existe_tutor.length>0){
			this.toastr.error("El tutor seleccionado ya se encuentra en la lista!");
		}else{
      if(persona_seleccionado.usuario){
				persona_seleccionado.usuario.repeticion_clave=persona_seleccionado.usuario.clave;
        persona_seleccionado.usuario.rol=this.rol_tutor;
			}else{
				persona_seleccionado.usuario={
          rol:this.rol_tutor,
          id_sucursal:Util.obtenerSucursalesUsuario(this.usuario)[0].id
        }
			}
			
			this.tutor={persona:persona_seleccionado,tutor_principal:false,activo:true};
      if(this.tutor.persona.usuario.rol){
        this.tutor.persona.usuario.id_rol=this.tutor.persona.usuario.rol?this.tutor.persona.usuario.rol.id:null;
        this.tutor.persona.usuario.aplicacionesUsuario=await this.buscarAplicacionesRol(this.tutor.persona.usuario.rol);
      }
			if(this.tutor.persona.fecha_nacimiento){
				this.tutor.persona.fecha_nacimiento=new Date(this.tutor.persona.fecha_nacimiento); 
				this.tutor.persona.fecha_nacimiento_texto={year:this.tutor.persona.fecha_nacimiento.getFullYear(), month: (this.tutor.persona.fecha_nacimiento.getMonth()+1),day:this.tutor.persona.fecha_nacimiento.getDate()};
			}
			this.busqueda_tutor_modal.close();
      this.obtenerIdiomas();
      this.obtenerParentescos();
      this.obtenerGradosAcademicos();
			this.abrirModalRegistroTutor();
		}
	}

  async abrirModalMigracionInscripcion(inscripcion){
      this.migracion_inscripcion_modal = this.modalService.open(MigracionInscripcionComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
      this.migracion_inscripcion_modal.componentInstance.migracion_inscripcion.inscripcion_anterior = inscripcion; 
      this.migracion_inscripcion_modal.componentInstance.inscripciones_destino = this.estudiante.inscripciones_colegio.filter(ic => parseInt(ic.gestion.nombre)>=parseInt(inscripcion.gestion.nombre) && ic.id!=inscripcion.id);
      this.migracion_inscripcion_modal.componentInstance.alTerminar.subscribe((res) => {
        this.migracion_inscripcion_modal.close();
      });
  }

  obtenerTiposDocumentosIdentidad(){
    this.generalService.obtenerClases(GlobalVariable.Dictionary.SIN.DOCUMENTOS_IDENTIDAD).subscribe((res:any)=>{
      this.sin_documentos_identidad=res?res.clases:[];
    });
  }

  establecerRazonSocial(){
    this.estudiante.persona.cliente.razon_social=(this.estudiante.persona.apellido_paterno?this.estudiante.persona.apellido_paterno+" ":"")+(this.estudiante.persona.apellido_paterno?this.estudiante.persona.apellido_materno+" ":"")+(this.estudiante.persona.nombres?this.estudiante.persona.nombres+" ":"");
  }

}
